<template>
  <ACard>
    <ACardTitle>Stripe Connect Integration</ACardTitle>
    <div class="mt-4">
      <div>
        <strong>Charges enabled:</strong> {{ chargesEnabled ? 'Yes' : 'No' }}
      </div>
      <div>
        <strong>Details submitted:</strong>
        {{ detailsSubmitted ? 'Yes' : 'No' }}
      </div>
      <div>
        <strong>Payouts enabled:</strong> {{ payoutsEnabled ? 'Yes' : 'No' }}
      </div>
    </div>
    <div v-if="!detailsSubmitted" class="mt-4">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="startOnboarding"
      >
        Start onboarding →
      </button>
    </div>
  </ACard>
</template>

<script>
import ACard from '@app/Shared/ACard.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';

export default {
  components: { ACardTitle, ACard },
  props: {
    chargesEnabled: {
      type: Boolean,
      required: true,
    },
    detailsSubmitted: {
      type: Boolean,
      required: true,
    },
    payoutsEnabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    startOnboarding() {
      this.$inertia.post('/integrations/stripe-connect/onboarding');
    },
  },
};
</script>
