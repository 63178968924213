<template>
  <label class="block text-base font-medium text-gray-700">
    <slot />
    <span v-if="optional" class="text-sm font-normal text-gray-400">
      (optional)</span
    >
    <span v-if="mandatory" class="font-normal text-red-500"> *</span>
  </label>
</template>

<script>
export default {
  props: {
    optional: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
