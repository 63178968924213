<template>
  <DashboardWrapper
    :title="$getPrimaryTranslation(branch.name)"
    :back-href="'/menus'"
  >
    <MenuManagementTabs :branch-id="branch.id" />
    <ACard class="mb-4">
      <div class="-mx-6 -mt-2 mb-4 border-b border-gray-200 px-4">
        <ATranslationTabs
          v-model:selected-language-code="selectedLanguageCode"
        />
      </div>

      <form class="space-y-4" @submit.prevent="submit">
        <AutoTranslateToggle
          v-model:auto-translate="form.autoTranslate"
          :selected-language-code="selectedLanguageCode"
        />

        <AInput
          v-model="form.name[selectedLanguageCode]"
          label="Menu name"
          placeholder="Main menu"
          :error="form.errors[`name.${selectedLanguageCode}`]"
          :mandatory="isPrimaryLanguageSelected"
          :disabled="areTranslatableFieldsDisabled"
        />

        <div>
          <ALabel for="banner"> Menu banner </ALabel>

          <MediaFile
            id="banner"
            class="mt-1"
            :file="form.banner"
            :image-url="
              branch.banner && !form.deleteBanner ? branch.banner : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="8"
            :preview-aspect-ratio="1200 / 700"
            @file="
              (file) => {
                form.banner = file;
                form.deleteBanner = false;
              }
            "
            @delete="
              () => {
                form.banner = null;
                form.deleteBanner = true;
              }
            "
          />

          <div class="mt-1 text-xs text-gray-500">
            Recommended resolution: 1200x700px
          </div>
          <div class="mt-1 text-xs text-gray-500">
            It is displayed at the top of your menu. If empty, restaurant's
            banner is used instead.
          </div>

          <AError v-if="form.errors.banner" class="mt-1">
            {{ form.errors.banner }}
          </AError>
        </div>

        <div>
          <ALabel for="default-product-image"> Default product image </ALabel>

          <MediaFile
            id="default-product-image"
            class="mt-1"
            :file="form.defaultProductImage"
            :image-url="
              branch.defaultProductImage && !form.deleteDefaultProductImage
                ? branch.defaultProductImage
                : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="20"
            @file="
              (file) => {
                form.defaultProductImage = file;
                form.deleteDefaultProductImage = false;
              }
            "
            @delete="
              () => {
                form.defaultProductImage = null;
                form.deleteDefaultProductImage = true;
              }
            "
          />

          <div class="mt-1 text-xs text-gray-500">
            It is used for products that do not have an image.
          </div>

          <AError v-if="form.errors.defaultProductImage" class="mt-1">
            {{ form.errors.defaultProductImage }}
          </AError>
        </div>

        <div>
          <ASelect
            v-model="form.isHomePageEnabled"
            label="Home page"
            :options="[
              { value: '1', label: 'Enabled' },
              { value: '0', label: 'Disabled' },
            ]"
            :value-resolver="(layoutType) => layoutType.value"
            :text-resolver="(layoutType) => layoutType.label"
            :error="form.errors.isHomePageEnabled"
          />
        </div>

        <div v-if="form.isHomePageEnabled === '1'">
          <ALabel for="homePageBackground"> Home page background </ALabel>

          <MediaFile
            id="homePageBackground"
            class="mt-1"
            :file="form.homePageBackground"
            :image-url="
              branch.homePageBackground && !form.deleteHomePageBackground
                ? branch.homePageBackground
                : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="2"
            @file="
              (file) => {
                form.homePageBackground = file;
                form.deleteHomePageBackground = false;
              }
            "
            @delete="
              () => {
                form.homePageBackground = null;
                form.deleteHomePageBackground = true;
              }
            "
          />

          <AError v-if="form.errors.homePageBackground" class="mt-1">
            {{ form.errors.homePageBackground }}
          </AError>
        </div>

        <div>
          <ASelect
            v-model="form.isCategoryTypesNavbarEnabled"
            label="Category types"
            :options="[
              { value: '1', label: 'Enabled' },
              { value: '0', label: 'Disabled' },
            ]"
            :value-resolver="(layoutType) => layoutType.value"
            :text-resolver="(layoutType) => layoutType.label"
            :error="form.errors.isCategoryTypesNavbarEnabled"
          />

          <div class="mt-1 text-xs text-gray-500">
            If enabled, you can assign type for each category when you
            create/edit it. When opening menu, people will be able to switch
            between different category types
            <i>("Food", "Drinks", "Desserts", "Shisha", "Offers")</i>.
          </div>
        </div>

        <div>
          <ALabel class="mb-1"> Global menu note </ALabel>
          <HtmlEditor
            :key="`global-menu-note-${selectedLanguageCode}`"
            v-model="form.globalMenuNote[selectedLanguageCode]"
            :disabled="areTranslatableFieldsDisabled"
          />
          <div class="mt-1 text-xs text-gray-500">
            It will be displayed at the top of your menu.
          </div>
          <AError
            v-if="form.errors[`globalMenuNote.${selectedLanguageCode}`]"
            class="mt-1"
            >{{ form.errors[`globalMenuNote.${selectedLanguageCode}`] }}</AError
          >
        </div>

        <div>
          <ALabel class="mb-1"> Global product note </ALabel>
          <HtmlEditor
            :key="`global-product-note-${selectedLanguageCode}`"
            v-model="form.globalProductNote[selectedLanguageCode]"
            :disabled="areTranslatableFieldsDisabled"
          />
          <div class="mt-1 text-xs text-gray-500">
            It will be displayed below the product description in the pop-up
            window of each product.
          </div>
          <AError
            v-if="form.errors[`globalProductNote.${selectedLanguageCode}`]"
            class="mt-1"
            >{{
              form.errors[`globalProductNote.${selectedLanguageCode}`]
            }}</AError
          >
        </div>

        <div>
          <ALabel class="mb-1"> Global category note </ALabel>
          <HtmlEditor
            :key="`global-category-note-${selectedLanguageCode}`"
            v-model="form.globalCategoryNote[selectedLanguageCode]"
            :disabled="areTranslatableFieldsDisabled"
          />
          <div class="mt-1 text-xs text-gray-500">
            It will be displayed below each category header.
          </div>
          <AError
            v-if="form.errors[`globalCategoryNote.${selectedLanguageCode}`]"
            class="mt-1"
            >{{
              form.errors[`globalCategoryNote.${selectedLanguageCode}`]
            }}</AError
          >
        </div>

        <div class="sticky bottom-0 flex w-full items-center bg-white py-3">
          <AButton type="submit" class="mt-3" :loading="form.processing">
            Save
          </AButton>
          <div v-if="form.isDirty" class="ml-3 text-gray-400">
            (has unsaved changes)
          </div>
        </div>
      </form>
    </ACard>
    <div class="flex justify-end">
      <button
        type="button"
        class="text-xs text-gray-400 hover:text-red-500 hover:underline"
        @click="modals.deleteMenu = true"
      >
        Delete menu
      </button>
    </div>

    <DeleteMenuModal
      v-if="modals.deleteMenu"
      :menu="branch"
      @close="modals.deleteMenu = false"
    />
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import AInput from '@app/Shared/AInput.vue';
import { useForm } from '@inertiajs/vue3';
import AButton from '@app/Shared/AButton.vue';
import ACard from '@app/Shared/ACard.vue';
import AError from '@app/Shared/AError.vue';
import ALabel from '@app/Shared/ALabel.vue';
import HtmlEditor from '@app/Shared/HtmlEditor.vue';
import MenuManagementTabs from '@app/Shared/Tabs/MenuManagementTabs.vue';
import ASelect from '@app/Shared/ASelect.vue';
import MediaFile from '@app/Shared/MediaFile.vue';
import DeleteMenuModal from '@app/Modals/DeleteMenuModal.vue';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';

export default {
  components: {
    AutoTranslateToggle,
    ATranslationTabs,
    DeleteMenuModal,
    MediaFile,
    ASelect,
    MenuManagementTabs,
    HtmlEditor,
    ALabel,
    AError,
    ACard,
    AButton,
    AInput,
    DashboardWrapper,
  },
  mixins: [HasTranslationTabs],
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        autoTranslate: {},
        name: {},
        homePageBackground: null,
        deleteHomePageBackground: false,
        banner: null,
        deleteBanner: false,
        defaultProductImage: null,
        deleteDefaultProductImage: false,
        globalMenuNote: {},
        globalProductNote: {},
        globalCategoryNote: {},
        isHomePageEnabled: '0',
        isCategoryTypesNavbarEnabled: '0',
      }),
      modals: {
        deleteMenu: false,
      },
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return [
        'form.name',
        'form.globalMenuNote',
        'form.globalProductNote',
        'form.globalCategoryNote',
      ];
    },
  },
  watch: {
    branch: {
      immediate: true,
      handler(branch) {
        this.form.defaults({
          autoTranslate: { ...branch.autoTranslate },
          name: { ...branch.name },
          deleteHomePageBackground: false,
          deleteBanner: false,
          deleteDefaultProductImage: false,
          globalMenuNote: { ...branch.globalMenuNote },
          globalProductNote: { ...branch.globalProductNote },
          globalCategoryNote: { ...branch.globalCategoryNote },
          isHomePageEnabled: branch.isHomePageEnabled ? '1' : '0',
          isCategoryTypesNavbarEnabled: branch.isCategoryTypesNavbarEnabled
            ? '1'
            : '0',
        });
        this.form.reset();
      },
    },
  },
  methods: {
    submit() {
      this.form.put(`/menus/${this.branch.id}`, {
        preserveScroll: true,
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        onSuccess: () => {
          this.$toast.success('Saved');
        },
      });
    },
  },
};
</script>
