<template>
  <div>
    <div class="flex flex-wrap">
      <div v-for="option in options" :key="option.type" class="mr-4 mb-4">
        <button
          type="button"
          class="relative flex min-h-[8.25rem] w-64 flex-col rounded-lg border px-4 py-4 transition hover:bg-gray-50 focus:outline-none"
          @click="option.onClick"
        >
          <span
            v-if="option.recommended"
            class="absolute top-1 right-1 rounded-md border border-lime-500 bg-lime-50 px-2 py-1 text-xs font-semibold text-lime-800"
            >Recommended</span
          >
          <span class="flex w-full items-start justify-between">
            <span class="block h-6 w-6"><Component :is="option.icon" /></span>

            <span
              v-if="option.type === 'COPY_FROM_OTHER_PLATFORM'"
              class="ml-2 flex space-x-1"
            >
              <TalabatLogo class="h-4 w-4" />
              <DeliverooLogo class="h-4 w-4" />
            </span>
          </span>
          <span class="mt-1 text-left font-semibold">{{ option.title }}</span>
          <span class="mt-1 text-left text-sm">{{ option.description }}</span>
        </button>
      </div>
    </div>

    <CreateEditCategoryModal
      v-if="modals.createCategory"
      :branch="branch"
      is-first-category
      @close="modals.createCategory = false"
    />

    <ImportMenuModal
      v-if="modals.importMenu"
      :branch="branch"
      @close="modals.importMenu = false"
    />

    <CopyMenuFromOtherPlatformModal
      v-if="modals.copyMenuFromOtherPlatform"
      :branch="branch"
      @close="modals.copyMenuFromOtherPlatform = false"
    />
  </div>
</template>

<script>
import ImportMenuModal from '@app/Modals/ImportMenuModal.vue';
import CreateEditCategoryModal from '@app/Modals/CreateEditCategoryModal.vue';
import CopyMenuFromOtherPlatformModal from '@app/Modals/CopyMenuFromOtherPlatformModal.vue';
import TalabatLogo from '@app/Shared/TalabatLogo.vue';
import DeliverooLogo from '@app/Shared/DeliverooLogo.vue';
import CopyBold from '@/phosphoricons/CopyBold.vue';
import TableBold from '@/phosphoricons/TableBold.vue';
import CursorClickBold from '@/phosphoricons/CursorClickBold.vue';

export default {
  components: {
    DeliverooLogo,
    TalabatLogo,
    CopyMenuFromOtherPlatformModal,
    CreateEditCategoryModal,
    ImportMenuModal,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createCategory: false,
        importMenu: false,
        copyMenuFromOtherPlatform: false,
      },
    };
  },
  computed: {
    options() {
      const isUae = this.$page.props._restaurant.countryCode === 'AE';

      return [
        {
          title: 'Add manually',
          description: 'Create categories and products one by one',
          recommended: !isUae,
          type: 'START_FROM_SCRATCH',
          icon: CursorClickBold,
          onClick: () => (this.modals.createCategory = true),
          available: true,
        },
        {
          title: 'Copy from delivery platform',
          description:
            'Our system will copy your menu with images in under a minute',
          type: 'COPY_FROM_OTHER_PLATFORM',
          icon: CopyBold,
          onClick: () => (this.modals.copyMenuFromOtherPlatform = true),
          available: isUae,
        },
        {
          title: 'Upload from Excel',
          description:
            'Download the sample Excel sheet, add your items and upload it',
          type: 'IMPORT_YOUR_MENU',
          icon: TableBold,
          onClick: () => (this.modals.importMenu = true),
          available: true,
        },
      ].filter(({ available }) => available);
    },
  },
};
</script>
