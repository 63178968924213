<template>
  <ACard>
    <ACardHeader>
      Order payment types

      <template #description>
        <p class="max-w-2xl">
          Order payment types are the different types of payments that can be
          chosen during checkout by your customers. For example, "Cash payment",
          "Card payment", etc.
        </p>
      </template>

      <template #actions>
        <AButton @click="modals.createOrderPaymentType = true">
          <template #icon><PlusBold /></template>
          Add order payment type
        </AButton>
      </template>
    </ACardHeader>

    <div>
      <div
        v-for="orderPaymentType in orderPaymentTypes"
        :key="orderPaymentType.id"
        class="mb-2 rounded border border-gray-200 bg-white"
      >
        <AExpandable v-slot="{ expanded, toggle }">
          <div class="flex flex-col justify-between py-3 pl-2 pr-2 sm:flex-row">
            <div class="flex flex-1 items-center">
              <button
                type="button"
                class="flex h-8 w-8 items-center justify-center rounded enabled:hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-25"
                :class="{
                  'rotate-180': expanded,
                }"
                @click="toggle"
              >
                <CaretDownBold class="w-4 text-gray-500" />
              </button>

              <div
                class="flex flex-1 cursor-pointer items-center self-stretch"
                @click="toggle"
              >
                <div class="ml-2 flex items-center">
                  <div class="font-bold text-gray-900">
                    {{ $getPrimaryTranslation(orderPaymentType.name) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-end space-x-2">
              <button
                v-tippy="{ content: 'Edit' }"
                type="button"
                class="p-1"
                @click="modals.editOrderPaymentType = orderPaymentType"
              >
                <PencilSimpleBold class="h-4 w-4 text-lime-500" />
              </button>
              <button
                v-tippy="{ content: 'Delete' }"
                type="button"
                class="p-1"
                @click="modals.deleteOrderPaymentType = orderPaymentType"
              >
                <TrashBold class="h-4 w-4 text-lime-500" />
              </button>
            </div>
          </div>

          <div v-show="expanded" class="border-t border-gray-200">
            <div class="py-2 px-12">
              <div>
                <span class="font-semibold">Description:</span>
                {{
                  $getPrimaryTranslation(orderPaymentType.description) || '--'
                }}
              </div>
            </div>
          </div>
        </AExpandable>
      </div>
    </div>

    <CreateEditOrderPaymentTypeModal
      v-if="modals.createOrderPaymentType"
      @close="modals.createOrderPaymentType = false"
    />

    <CreateEditOrderPaymentTypeModal
      v-if="modals.editOrderPaymentType"
      :existing-order-payment-type="modals.editOrderPaymentType"
      @close="modals.editOrderPaymentType = false"
    />

    <DeleteOrderPaymentTypeModal
      v-if="modals.deleteOrderPaymentType"
      :order-payment-type="modals.deleteOrderPaymentType"
      @close="modals.deleteOrderPaymentType = false"
    />
  </ACard>
</template>

<script>
import ACard from '@app/Shared/ACard.vue';
import ACardHeader from '@/app/Shared/ACardHeader.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';
import AButton from '@/app/Shared/AButton.vue';
import CreateEditOrderPaymentTypeModal from '@/app/Modals/CreateEditOrderPaymentTypeModal.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import AExpandable from '@/app/Shared/AExpandable.vue';
import DeleteOrderPaymentTypeModal from '@/app/Modals/DeleteOrderPaymentTypeModal.vue';

export default {
  components: {
    DeleteOrderPaymentTypeModal,
    AExpandable,
    PencilSimpleBold,
    CaretDownBold,
    TrashBold,
    CreateEditOrderPaymentTypeModal,
    AButton,
    PlusBold,
    ACardHeader,
    ACard,
  },
  props: {
    orderPaymentTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createOrderPaymentType: false,
        editOrderPaymentType: false,
        deleteOrderPaymentType: false,
      },
    };
  },
};
</script>
