<template>
  <ACard>
    <ACardHeader>
      Order types

      <template #description>
        <p class="max-w-2xl">
          Order types are the different types of orders that can be chosen
          during checkout by your customers. For example, "Dine-in", "Home
          delivery", etc.
        </p>
      </template>

      <template #actions>
        <AButton @click="modals.createOrderType = true">
          <template #icon><PlusBold /></template>
          Add order type
        </AButton>
      </template>
    </ACardHeader>

    <div>
      <div
        v-for="orderType in orderTypes"
        :key="orderType.id"
        class="mb-2 rounded border border-gray-200 bg-white"
      >
        <AExpandable v-slot="{ expanded, toggle }">
          <div class="flex flex-col justify-between py-3 pl-2 pr-2 sm:flex-row">
            <div class="flex flex-1 items-center">
              <button
                type="button"
                class="flex h-8 w-8 items-center justify-center rounded enabled:hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-25"
                :class="{
                  'rotate-180': expanded,
                }"
                @click="toggle"
              >
                <CaretDownBold class="w-4 text-gray-500" />
              </button>

              <div
                class="flex flex-1 cursor-pointer items-center self-stretch"
                @click="toggle"
              >
                <div class="ml-2 flex items-center">
                  <div class="font-bold text-gray-900">
                    {{ $getPrimaryTranslation(orderType.name) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-end space-x-2">
              <button
                v-tippy="{ content: 'Edit' }"
                type="button"
                class="p-1"
                @click="modals.editOrderType = orderType"
              >
                <PencilSimpleBold class="h-4 w-4 text-lime-500" />
              </button>
              <button
                v-tippy="{ content: 'Delete' }"
                type="button"
                class="p-1"
                @click="modals.deleteOrderType = orderType"
              >
                <TrashBold class="h-4 w-4 text-lime-500" />
              </button>
            </div>
          </div>

          <div v-show="expanded" class="border-t border-gray-200">
            <div class="py-2 px-12">
              <div>
                <span class="font-semibold">Description:</span>
                {{ $getPrimaryTranslation(orderType.description) || '--' }}
              </div>

              <ACheckoutFields v-slot="{ fields }" :order-type="orderType">
                <div>
                  <span class="font-semibold">Checkout fields: </span>
                  <span v-if="fields.length === 0">--</span>
                </div>
                <div v-for="(field, fieldIndex) in fields" :key="fieldIndex">
                  <div>
                    • {{ field.label }}
                    <span class="text-gray-500">
                      ({{ field.required ? 'required' : 'optional' }})
                    </span>
                  </div>
                </div>
              </ACheckoutFields>
            </div>
          </div>
        </AExpandable>
      </div>
    </div>

    <CreateEditOrderTypeModal
      v-if="modals.createOrderType"
      @close="modals.createOrderType = false"
    />

    <CreateEditOrderTypeModal
      v-if="modals.editOrderType"
      :existing-order-type="modals.editOrderType"
      @close="modals.editOrderType = false"
    />

    <DeleteOrderTypeModal
      v-if="modals.deleteOrderType"
      :order-type="modals.deleteOrderType"
      @close="modals.deleteOrderType = false"
    />
  </ACard>
</template>

<script>
import ACard from '@app/Shared/ACard.vue';
import ACardHeader from '@/app/Shared/ACardHeader.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';
import AButton from '@/app/Shared/AButton.vue';
import CreateEditOrderTypeModal from '@/app/Modals/CreateEditOrderTypeModal.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import AExpandable from '@/app/Shared/AExpandable.vue';
import DeleteOrderTypeModal from '@/app/Modals/DeleteOrderTypeModal.vue';
import ACheckoutFields from '@/app/Shared/ACheckoutFields.vue';

export default {
  components: {
    ACheckoutFields,
    DeleteOrderTypeModal,
    AExpandable,
    PencilSimpleBold,
    CaretDownBold,
    TrashBold,
    CreateEditOrderTypeModal,
    AButton,
    PlusBold,
    ACardHeader,
    ACard,
  },
  props: {
    orderTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createOrderType: false,
        editOrderType: false,
        deleteOrderType: false,
      },
    };
  },
};
</script>
