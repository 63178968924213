<template>
  <DashboardWrapper
    :title="
      success
        ? 'Thanks for choosing Apetito Menu! 🎉'
        : `Current Subscription for ${$page.props._restaurant.name}`
    "
  >
    <ACard>
      <div class="flex flex-col">
        <template v-if="details.isCancelled">
          <div>Your subscription has been cancelled.</div>
          <div>If you wish to renew, please message us.</div>
        </template>

        <template v-else>
          <template v-if="details.actionUrl">
            <div
              class="rounded-lg border border-red-500 bg-red-50 p-4 text-red-600"
            >
              <div>
                <strong>Action Required:</strong> we were unable to charge your
                card, complete the payment by clicking the button below.
              </div>
              <div class="flex">
                <a
                  :href="details.actionUrl"
                  target="_blank"
                  class="mt-2 block rounded-lg bg-red-600 px-3 py-1 text-white transition hover:bg-red-700"
                  >Complete Payment</a
                >
              </div>
            </div>
          </template>
          <template v-else-if="details.nextPayment">
            <div>
              <strong>Next payment: </strong>
              {{ details.nextPayment.currency }}
              {{ details.nextPayment.amount }}
              <span class="italic text-gray-500"
                >({{ nextPaymentDateFormatted }})</span
              >
            </div>
            <div v-if="details.card">
              <strong>Payment method: </strong>
              •••• {{ details.card.last4 }} exp. {{ details.card.expiry }}
            </div>
            <div>
              <strong>Plan benefits:</strong>
            </div>

            <div
              class="mt-1 max-w-sm overflow-hidden rounded-lg border border-gray-200"
            >
              <table class="w-full">
                <tr
                  v-for="(feature, featureIndex) in features"
                  :key="featureIndex"
                  class="border-b border-gray-200 last:border-b-0 odd:bg-gray-50"
                >
                  <td class="w-3/5 px-4 py-2">
                    <FeatureName :feature="feature" />
                  </td>
                  <td class="px-4 py-2">
                    <FeatureAvailability
                      :feature="feature"
                      :benefits="{
                        maxLanguages: $page.props._restaurant.maxLanguages,
                        allowCustomHostname:
                          $page.props._restaurant.allowCustomHostname,
                      }"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </template>
        <div class="mt-4 flex flex-wrap gap-3">
          <AButton href="/billing/change-plan" class="btn btn-primary btn-sm">
            <template #icon>
              <ArrowsClockwiseRegular />
            </template>
            Change plan
          </AButton>

          <AButton href="/billing/change-card" class="btn btn-primary btn-sm">
            <template #icon>
              <CreditCardRegular />
            </template>
            Change card
          </AButton>

          <AButton
            v-if="$page.props._restaurant.countryCode === 'AE'"
            href="/billing/invoices"
            class="btn btn-primary btn-sm"
          >
            <template #icon>
              <ReceiptRegular />
            </template>
            View invoices
          </AButton>
        </div>
      </div>
    </ACard>

    <div class="mt-6 mb-20 flex justify-end">
      <AButton href="/billing/cancel-subscription" variant="outline-secondary">
        Cancel subscription
      </AButton>
    </div>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AButton from '@app/Shared/AButton.vue';
import { DateTime } from 'luxon';
import CreditCardRegular from '@/phosphoricons/CreditCardRegular.vue';
import ReceiptRegular from '@/phosphoricons/ReceiptRegular.vue';
import ArrowsClockwiseRegular from '@/phosphoricons/ArrowsClockwiseRegular.vue';
import getFeatures from '@/utils/getFeatures';
import FeatureName from '@/app/Shared/FeatureName.vue';
import FeatureAvailability from '@/app/Shared/FeatureAvailability.vue';
import celebrate from '@/utils/celebrate';

export default {
  components: {
    FeatureAvailability,
    FeatureName,
    ArrowsClockwiseRegular,
    ReceiptRegular,
    CreditCardRegular,
    AButton,
    ACard,
    DashboardWrapper,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nextPaymentDateFormatted() {
      return DateTime.fromISO(this.details.nextPayment.date).toLocaleString(
        DateTime.DATETIME_FULL,
      );
    },
    features() {
      return getFeatures();
    },
  },
  mounted() {
    if (this.success) {
      window.fbq && window.fbq('track', 'Subscribe');

      window.gtag &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-10942188799/XpWECK_i46EYEP-Z0uEo',
        });

      celebrate();
    }
  },
};
</script>
