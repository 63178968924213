<template>
  <Head>
    <title>{{ title }} - Apetito Menu</title>
  </Head>

  <div class="mb-4 text-2xl font-bold">
    <Link v-if="backHref" :href="backHref" class="mr-1">←</Link>
    <h1 class="inline">{{ title }}</h1>
  </div>
  <slot />
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';

export default {
  components: { Head, Link },
  props: {
    title: {
      type: String,
      required: true,
    },
    backHref: {
      type: String,
      default: null,
    },
  },
};
</script>
