<template>
  <div>
    <div class="flex flex-col space-y-3">
      <template v-if="colorsCustomizable">
        <div>
          <ALabel class="flex items-center">
            <input v-model="hasBackgroundColor" type="checkbox" class="mr-1" />
            Background color
          </ALabel>
          <AColorInput v-if="hasBackgroundColor" v-model="backgroundColor" />
          <p v-else class="mt-1 text-sm text-gray-500">
            Background for your QR code will be transparent.
          </p>
        </div>

        <AColorInput v-model="qrColor" label="QR color" />

        <AAlert
          v-if="!isContrastSufficientForSelectedColors"
          type="warning"
          class="max-w-sm"
        >
          <template #title>
            For better readability with all QR code scanners, we suggest
            increasing the contrast between the background color and the QR code
            color.
          </template>
        </AAlert>
      </template>

      <slot name="additional-settings" />

      <QRCode
        ref="qr"
        :value="value"
        :options="{
          width: 1024,
          margin: 3,
          color: {
            light: !hasBackgroundColor ? '#00000000' : backgroundColor,
            dark: qrColor,
          },
          errorCorrectionLevel: 'Q',
        }"
        tag="img"
        class="w-32 cursor-pointer rounded-lg border border-gray-300"
        @click="download"
      />

      <div v-if="downloadable">
        <AButton @click="download"> Download QR code </AButton>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from '@chenfengyuan/vue-qrcode';
import { saveAs } from 'file-saver';
import AButton from '@app/Shared/AButton.vue';
import AColorInput from '@app/Shared/AColorInput.vue';
import ALabel from '@app/Shared/ALabel.vue';
import Color from 'color';
import AAlert from '@app/Shared/AAlert.vue';
import kebabCase from 'lodash/kebabCase';

export default {
  components: { AAlert, ALabel, AColorInput, AButton, QRCode },
  props: {
    value: {
      type: String,
      required: true,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    colorsCustomizable: {
      type: Boolean,
      default: false,
    },
    preferredQrColor: {
      type: String,
      default: '#000000',
    },
    restaurantName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      backgroundColor: '#ffffff',
      qrColor: '#000000',
      hasBackgroundColor: true,
    };
  },
  computed: {
    defaultQrColor() {
      if (
        this.preferredQrColor !== '#3b6935' &&
        this.isContrastSufficient('#ffffff', this.preferredQrColor)
      ) {
        return this.preferredQrColor;
      }

      return '#000000';
    },
    isContrastSufficientForSelectedColors() {
      return this.isContrastSufficient(
        this.hasBackgroundColor ? this.backgroundColor : '#ffffff',
        this.qrColor,
      );
    },
  },
  created() {
    this.qrColor = this.defaultQrColor;
  },
  methods: {
    download() {
      saveAs(
        this.$refs.qr.$el.src,
        `${
          this.restaurantName ? kebabCase(this.restaurantName) + '-' : ''
        }menu-qr-code.png`,
      );
    },
    isContrastSufficient(color1, color2) {
      return (
        Color(color1).contrast(Color(color2)) >= 4.5 &&
        Color(color2).contrast(Color(color1)) >= 4.5
      );
    },
  },
};
</script>
