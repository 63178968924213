<template>
  <div
    class="rounded-lg border border-gray-100 bg-white p-6"
    style="box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px"
  >
    <slot />
  </div>
</template>

<script>
export default {};
</script>
