<template>
  <div>
    <div class="mb-1 flex justify-between">
      <div><slot name="label" :progress="progress" /></div>
      <div>{{ parseInt(progress) }}%</div>
    </div>
    <div class="h-4 w-full overflow-hidden rounded-full bg-gray-200">
      <div
        class="h-full bg-lime-500 transition-[width]"
        :style="{
          width: `${progress}%`,
          'transition-duration': `${intervalMs}ms`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    durationMs: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      progress: 0,
      progressSlowdownAfter: 90,
      progressLimit: 95,
      intervalMs: this.durationMs / (100 / this.step),
    };
  },
  mounted() {
    this.intervalId = setInterval(() => {
      let step = this.step;

      if (this.progress >= this.progressSlowdownAfter) {
        step /= 4;
      }

      this.progress = Math.min(this.progress + step, this.progressLimit);
    }, this.intervalMs);
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
};
</script>
