<template>
  <Link
    :key="name"
    :href="href"
    :class="[
      'mb-1 flex transform items-center rounded-md px-2 py-2 text-sm transition-all',
      icon ? 'font-medium' : 'font-normal',
      current
        ? 'bg-lime-500 text-white hover:bg-lime-600'
        : 'text-gray-800 hover:bg-lime-100 hover:text-lime-900',
    ]"
  >
    <div class="mr-2 h-5 w-5 flex-shrink-0">
      <component
        :is="icon"
        v-if="icon"
        :class="['h-5 w-5']"
        aria-hidden="true"
      />
    </div>
    {{ name }}
  </Link>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
  components: { Link },
  props: {
    name: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: null,
    },
  },
};
</script>
