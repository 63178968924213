<template>
  <AuthLayout title="Reset password">
    <form @submit.prevent="resetPassword">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Reset your password
      </h1>

      <div class="mt-6 space-y-4">
        <div>
          <AInput
            v-model="form.email"
            label="Email"
            :error="form.errors.email"
            type="email"
            :disabled="true"
          />
        </div>

        <div>
          <AInput
            v-model="form.password"
            label="New password"
            placeholder="Enter your new password"
            :error="form.errors.password"
            type="password"
            autocomplete="new-password"
          />
        </div>

        <div>
          <AInput
            v-model="form.passwordConfirmation"
            label="Confirm your password"
            placeholder="Re-enter your new password"
            :error="form.errors.passwordConfirmation"
            type="password"
            autocomplete="new-password"
          />
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          class="btn btn-primary w-full"
          :disabled="form.processing"
          :data-loading="form.processing"
        >
          Reset password
        </button>
      </div>

      <div class="mt-6 text-center text-gray-500">
        Don't want to reset password?
        <InertiaLink href="/login" class="text-lime-500">Login</InertiaLink>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';

export default {
  components: {
    AInput,
    AuthLayout,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        email: this.email ?? '',
        password: '',
        passwordConfirmation: '',
        token: this.token,
      }),
    };
  },
  methods: {
    async resetPassword() {
      await this.form.post('/reset-password', {
        onSuccess: () => {
          this.$toast.success('Your password has been reset!');
        },
      });
    },
  },
};
</script>
