<template>
  <DashboardWrapper title="Menus">
    <ACard class="mb-4">
      <ACardHeader>
        <template #actions>
          <AButton @click="modals.createMenu = true">
            <template #icon><PlusBold /></template>
            Create menu
          </AButton>
        </template>
      </ACardHeader>
      <div>
        <Draggable
          :list="branches"
          :item-key="(branch) => branch.id"
          handle=".branch-draggable-handle"
          :animation="200"
          :delay="250"
          :delay-on-touch-only="true"
          @change="onBranchDrag"
        >
          <template #item="{ element: branch }">
            <div class="mb-2 rounded border border-gray-200 bg-white">
              <div
                class="flex flex-col justify-between py-3 pl-2 pr-2 sm:flex-row"
              >
                <div class="flex flex-1 items-center">
                  <button
                    v-tippy="{ content: 'Drag to reorder' }"
                    type="button"
                    class="branch-draggable-handle flex h-8 w-8 cursor-move items-center justify-center rounded hover:bg-gray-100"
                  >
                    <DotsSixVerticalBold class="w-4 text-gray-500" />
                  </button>

                  <div class="flex flex-1 items-center self-stretch">
                    <InertiaLink
                      :href="`/menus/${branch.id}`"
                      class="ml-2 mr-1 w-full py-2 font-bold text-gray-900"
                    >
                      {{ $getPrimaryTranslation(branch.name) || '--' }}
                      <span class="whitespace-nowrap font-normal text-gray-400"
                        >({{ branch.categoriesCount }} categories)</span
                      >
                    </InertiaLink>
                  </div>
                </div>

                <div class="flex items-center justify-end space-x-2 pr-2">
                  <div class="p-1 pr-3">
                    <AToggle
                      v-tippy="{
                        content: branch.isEnabled ? 'Hide menu' : 'Show menu',
                      }"
                      :model-value="branch.isEnabled"
                      @update:model-value="
                        onBranchIsEnabledUpdate({
                          branch,
                          isEnabled: $event,
                        })
                      "
                    />
                  </div>
                  <InertiaLink
                    :href="`/menus/${branch.id}`"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Edit menu
                  </InertiaLink>
                </div>
              </div>
            </div>
          </template>
        </Draggable>
      </div>

      <CreateMenuModal
        v-if="modals.createMenu"
        @close="modals.createMenu = false"
      />
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import ACardHeader from '@app/Shared/ACardHeader.vue';
import Draggable from 'vuedraggable';
import AToggle from '@app/Shared/AToggle.vue';
import AButton from '@app/Shared/AButton.vue';
import CreateMenuModal from '@app/Modals/CreateMenuModal.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';
import DotsSixVerticalBold from '@/phosphoricons/DotsSixVerticalBold.vue';

export default {
  components: {
    DotsSixVerticalBold,
    PlusBold,
    CreateMenuModal,
    AButton,
    ACardHeader,
    ACard,
    DashboardWrapper,
    Draggable,
    AToggle,
  },
  props: {
    branches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createMenu: false,
      },
    };
  },
  methods: {
    async onBranchDrag() {
      await this.$inertia.put(
        `/menus/order`,
        {
          newOrder: this.branches.map((branch) => branch.id),
        },
        { preserveScroll: true },
      );
    },
    async onBranchIsEnabledUpdate({ branch, isEnabled }) {
      branch.isEnabled = isEnabled;

      await this.$inertia.put(
        `/menus/${branch.id}/availability`,
        {
          isEnabled,
        },
        { preserveScroll: true },
      );
    },
  },
};
</script>
