<template>
  <AuthLayout title="Reset your password">
    <form @submit.prevent="sendPasswordResetLink">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Reset your password
      </h1>

      <div class="mt-2 text-center text-sm text-gray-500">
        Enter the email address associated with your account and we'll send you
        a link to reset your password.
      </div>

      <div class="mt-6 space-y-4">
        <div>
          <AInput
            v-model="form.email"
            label="Email"
            placeholder="Enter your email"
            :error="form.errors.email"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
          />
        </div>
      </div>

      <div class="mt-4">
        <AButton block type="submit" :loading="form.processing">
          Continue
        </AButton>
      </div>
    </form>

    <div class="mt-4 flex justify-center">
      <InertiaLink href="/login" class="text-lime-500"
        >Return to login</InertiaLink
      >
    </div>
  </AuthLayout>
</template>

<script>
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';

export default {
  components: {
    AInput,
    AuthLayout,
    AButton,
  },
  data() {
    return {
      form: useForm({
        email: '',
      }),
    };
  },
  methods: {
    async sendPasswordResetLink() {
      await this.form.post('/forgot-password', {
        onSuccess: () => {
          // No matter if email exists or not, we won't expose this info
          // and will show success message anyways.
          this.$toast.success(
            'Instructions to reset your password will be sent to you shortly. Please check your email.',
          );
        },
      });
    },
  },
};
</script>
