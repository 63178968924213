<template>
  <slot :fields="fields" />
</template>

<script>
export default {
  props: {
    orderType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fields() {
      return [
        {
          label: 'Name',
          enabledAttribute: 'isNameEnabled',
          requiredAttribute: 'isNameRequired',
        },
        {
          label: 'Email',
          enabledAttribute: 'isEmailEnabled',
          requiredAttribute: 'isEmailRequired',
        },
        {
          label: 'Phone',
          enabledAttribute: 'isPhoneEnabled',
          requiredAttribute: 'isPhoneRequired',
        },
        {
          label: 'Note',
          enabledAttribute: 'isNoteEnabled',
          requiredAttribute: null,
        },
      ]
        .map((field) => ({
          label: field.label,
          enabled: this.orderType[field.enabledAttribute],
          required: field.requiredAttribute
            ? this.orderType[field.requiredAttribute]
            : false,
        }))
        .filter(({ enabled }) => enabled);
    },
  },
};
</script>
