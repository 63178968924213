<template>
  <AModal @close="$emit('close')">
    <div>
      Are you sure you want to delete
      <strong>"{{ $getPrimaryTranslation(modifierSet.name) }}"</strong> modifier
      set?
    </div>

    <div class="mt-4 flex space-x-2">
      <AButton variant="danger" :loading="form.processing" @click="destroy">
        Delete
      </AButton>
      <AButton variant="outline-secondary" @click="$emit('close')">
        Cancel
      </AButton>
    </div>
  </AModal>
</template>
<script>
import AModal from '@app/Shared/AModal.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';

export default {
  components: { AButton, AModal },
  props: {
    modifierSet: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({}),
    };
  },
  methods: {
    async destroy() {
      await this.form.delete(`/modifier-sets/${this.modifierSet.id}`, {
        onSuccess: () => this.$emit('close'),
        preserveScroll: true,
      });
    },
  },
};
</script>
