<template>
  <ATabs>
    <ATab
      :href="`/menus/${branchId}?tab=overview`"
      :current="
        getURL().pathname === `/menus/${branchId}` &&
        getURL().searchParams.get('tab') === 'overview'
      "
    >
      Menu Items
    </ATab>
    <ATab
      v-if="
        $page.props._restaurant.user.permissions.manageOrders ||
        $page.props._restaurant.isModifiersForceEnabled
      "
      :href="`/menus/${branchId}?tab=modifiers`"
      :current="
        getURL().pathname === `/menus/${branchId}` &&
        getURL().searchParams.get('tab') === 'modifiers'
      "
    >
      Modifiers
    </ATab>
    <ATab
      :href="`/menus/${branchId}?tab=settings`"
      :current="
        getURL().pathname === `/menus/${branchId}` &&
        getURL().searchParams.get('tab') === 'settings'
      "
    >
      Settings
    </ATab>
  </ATabs>
</template>

<script>
import ATabs from '@app/Shared/ATabs.vue';
import ATab from '@app/Shared/ATab.vue';
export default {
  components: { ATab, ATabs },
  props: {
    branchId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getURL() {
      return new URL(location.href);
    },
  },
};
</script>
