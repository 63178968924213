<template>
  <AModal @close="$emit('close')">
    <div class="space-y-4">
      <ACardTitle>Upload custom font</ACardTitle>

      <div class="h-[150px]">
        <FilePicker :loading="uploading" @file="uploadCustomFont">
          <template #description>
            Only <span class="font-mono">.ttf</span> and
            <span class="font-mono">.otf</span> files supported
          </template>
        </FilePicker>
      </div>

      <div
        v-if="uploadingError"
        class="mt-4 text-center text-sm font-semibold text-red-500"
      >
        {{ uploadingError }}
      </div>
    </div>
  </AModal>
</template>
<script>
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import FilePicker from '@app/Shared/FilePicker.vue';
import axios from 'axios';

export default {
  components: {
    FilePicker,
    ACardTitle,
    AModal,
  },
  emits: ['close', 'upload'],
  data() {
    return {
      uploading: false,
      uploadingError: null,
    };
  },
  methods: {
    uploadCustomFont(file) {
      this.uploading = true;
      this.uploadingError = null;

      const form = new FormData();
      form.append('file', file);

      axios
        .post('/ajax/custom-fonts', form)
        .then((response) => {
          this.$emit('upload', response.data.data);
          this.$emit('close');
        })
        .catch((err) => {
          console.log(err);
          this.uploadingError = err.response.data.message;
        })
        .finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>
