<template>
  <DashboardWrapper title="Subscription Checkout">
    <ACard>
      <div>You're almost there...</div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
export default {
  components: { ACard, DashboardWrapper },
  props: {
    payLink: {
      type: String,
      required: true,
    },
    successUrl: {
      type: String,
      required: true,
    },
    cancelUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.Paddle.Checkout.open({
      override: this.payLink,
      closeCallback: () => {
        location.href = this.cancelUrl;
      },
      successCallback: () => {
        location.href = this.successUrl;
      },
    });
  },
};
</script>
