<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div>
      <div class="space-y-4">
        <AutoTranslateToggle
          v-model:auto-translate="form.autoTranslate"
          :selected-language-code="selectedLanguageCode"
        />

        <AInput
          v-model="form.name[selectedLanguageCode]"
          label="Order payment type name"
          placeholder=""
          :error="form.errors[`name.${selectedLanguageCode}`]"
          :mandatory="isPrimaryLanguageSelected"
          :disabled="areTranslatableFieldsDisabled"
        />

        <AInput
          v-model="form.description[selectedLanguageCode]"
          label="Order payment type description"
          placeholder=""
          :error="form.errors[`description.${selectedLanguageCode}`]"
          type="textarea"
          :rows="3"
          :disabled="areTranslatableFieldsDisabled"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{
            existingOrderPaymentType
              ? 'Save order payment type'
              : 'Create order payment type'
          }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import cloneDeep from 'lodash/cloneDeep.js';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';

export default {
  components: {
    AutoTranslateToggle,
    ATranslationTabs,
    AModal,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    existingOrderPaymentType: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: this.existingOrderPaymentType
          ? cloneDeep(this.existingOrderPaymentType.autoTranslate)
          : {},
        name: this.existingOrderPaymentType
          ? { ...this.existingOrderPaymentType.name }
          : {},
        description: this.existingOrderPaymentType
          ? { ...this.existingOrderPaymentType.description }
          : {},
      }),
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return ['form.name', 'form.description'];
    },
  },
  methods: {
    async submit() {
      if (!this.existingOrderPaymentType) {
        await this.form.post('/order-payment-types', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(
          `/order-payment-types/${this.existingOrderPaymentType.id}`,
          {
            onSuccess: () => {
              this.$emit('close');
              this.$toast.success('Saved');
            },
            onError: () => {
              this.$toast.error('Please fix all highlighted fields');
            },
            preserveScroll: true,
          },
        );
      }
    },
  },
};
</script>
