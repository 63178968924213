<template>
  <div
    class="sticky top-[120px] flex h-[calc(100vh-235px)] max-h-[525px] w-[265px] flex-col overflow-hidden rounded-[36px] border-[10px] border-black bg-white text-gray-900"
    style="--preview-scale: 0.65"
  >
    <div class="flex h-[28px] w-full items-start justify-center px-2">
      <div
        class="flex h-full flex-1 items-center justify-center text-xs font-semibold"
      >
        {{ $luxon.DateTime.now().toFormat('h:mm') }}
      </div>
      <div class="h-[18px] w-[112px] rounded-b-[12px] bg-black" />
      <div class="flex h-full flex-1 items-center justify-center">
        <svg
          width="67"
          height="12"
          viewBox="0 0 67 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[50px]"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.3996 0.666626H15.3996C14.8473 0.666626 14.3996 1.11434 14.3996 1.66663V10.3333C14.3996 10.8856 14.8473 11.3333 15.3996 11.3333H16.3996C16.9519 11.3333 17.3996 10.8856 17.3996 10.3333V1.66663C17.3996 1.11434 16.9519 0.666626 16.3996 0.666626ZM10.7329 2.99993H11.7329C12.2852 2.99993 12.7329 3.44764 12.7329 3.99993V10.3333C12.7329 10.8855 12.2852 11.3333 11.7329 11.3333H10.7329C10.1806 11.3333 9.73287 10.8855 9.73287 10.3333V3.99993C9.73287 3.44764 10.1806 2.99993 10.7329 2.99993ZM7.06639 5.33342H6.06639C5.5141 5.33342 5.06639 5.78113 5.06639 6.33342V10.3334C5.06639 10.8857 5.5141 11.3334 6.06639 11.3334H7.06639C7.61867 11.3334 8.06639 10.8857 8.06639 10.3334V6.33342C8.06639 5.78113 7.61867 5.33342 7.06639 5.33342ZM2.39966 7.33343H1.39966C0.847373 7.33343 0.399658 7.78114 0.399658 8.33343V10.3334C0.399658 10.8857 0.847373 11.3334 1.39966 11.3334H2.39966C2.95194 11.3334 3.39966 10.8857 3.39966 10.3334V8.33343C3.39966 7.78114 2.95194 7.33343 2.39966 7.33343Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.0363 2.7946C32.2522 2.7947 34.3833 3.64613 35.9893 5.17293C36.1102 5.29081 36.3035 5.28932 36.4226 5.1696L37.5786 4.00293C37.6389 3.94221 37.6725 3.85996 37.672 3.77438C37.6715 3.6888 37.6369 3.60695 37.5759 3.54693C33.3608 -0.492614 26.711 -0.492614 22.4959 3.54693C22.4349 3.6069 22.4002 3.68873 22.3997 3.77431C22.3991 3.85989 22.4327 3.94217 22.4929 4.00293L23.6493 5.1696C23.7683 5.2895 23.9617 5.29099 24.0826 5.17293C25.6887 3.64603 27.8202 2.79459 30.0363 2.7946ZM30.0363 6.59016C31.2538 6.59009 32.4278 7.04262 33.3303 7.85983C33.4524 7.97581 33.6446 7.97329 33.7636 7.85416L34.9183 6.68749C34.9791 6.6263 35.0128 6.54328 35.012 6.45702C35.0111 6.37075 34.9757 6.28844 34.9136 6.22849C32.1654 3.67211 27.9095 3.67211 25.1613 6.22849C25.0992 6.28844 25.0638 6.37079 25.063 6.45709C25.0622 6.54338 25.096 6.62639 25.157 6.68749L26.3113 7.85416C26.4303 7.97329 26.6226 7.97581 26.7446 7.85983C27.6465 7.04316 28.8196 6.59067 30.0363 6.59016ZM32.3494 9.14396C32.3512 9.23046 32.3172 9.31386 32.2554 9.37447L30.2581 11.3901C30.1995 11.4494 30.1197 11.4827 30.0364 11.4827C29.9531 11.4827 29.8733 11.4494 29.8147 11.3901L27.8171 9.37447C27.7554 9.31382 27.7214 9.23039 27.7232 9.14388C27.7251 9.05738 27.7625 8.97546 27.8267 8.91747C29.1023 7.83859 30.9705 7.83859 32.2461 8.91747C32.3102 8.97551 32.3476 9.05745 32.3494 9.14396Z"
            fill="currentColor"
          />
          <path
            opacity="0.4"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M45.3387 1.33334H62.0053C62.9258 1.33334 63.672 2.07953 63.672 3.00001V9.00001C63.672 9.92049 62.9258 10.6667 62.0053 10.6667H45.3387C44.4182 10.6667 43.672 9.92049 43.672 9.00001V3.00001C43.672 2.07953 44.4182 1.33334 45.3387 1.33334ZM42.672 3.00001C42.672 1.52725 43.8659 0.333344 45.3387 0.333344H62.0053C63.4781 0.333344 64.672 1.52725 64.672 3.00001V9.00001C64.672 10.4728 63.4781 11.6667 62.0053 11.6667H45.3387C43.8659 11.6667 42.672 10.4728 42.672 9.00001V3.00001ZM67 6.00006C67 6.87319 66.4767 7.66128 65.6719 8.00006V4.00006C66.4767 4.33884 67 5.12693 67 6.00006Z"
            fill="currentColor"
          />
          <rect
            x="44.6719"
            y="2.33334"
            width="18"
            height="7.33333"
            rx="1.33333"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="flex-1">
      <iframe
        ref="iframe"
        :key="`iframe-${restaurant.id}-${enabledBranchesCount}`"
        :src="$page.props._iframePreviewUrl"
        class="h-[calc(100%/var(--preview-scale))] w-[calc(100%/var(--preview-scale))] origin-top-left scale-[var(--preview-scale)]"
      />
    </div>
    <div
      class="flex flex-col items-center border-t border-gray-100 bg-gray-50 px-5 pt-2 pb-1"
    >
      <a
        class="mb-2 flex w-full items-center justify-center"
        :href="$page.props._restaurant.menuUrl"
        target="_blank"
      >
        <svg
          width="10"
          height="14"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-[2px] block h-[8px] text-gray-500"
        >
          <path
            d="M1.79341 13.5H8.20659C9.0756 13.5 9.5 13.0736 9.5 12.1407V7.24987C9.5 6.4103 9.1497 5.97719 8.42889 5.9039V4.22476C8.42889 1.71271 6.76497 0.5 5 0.5C3.23503 0.5 1.57111 1.71271 1.57111 4.22476V5.93721C0.910928 6.03716 0.5 6.46361 0.5 7.24987V12.1407C0.5 13.0736 0.924401 13.5 1.79341 13.5ZM2.65569 4.08483C2.65569 2.41235 3.74027 1.52614 5 1.52614C6.25973 1.52614 7.34431 2.41235 7.34431 4.08483V5.89723L2.65569 5.9039V4.08483Z"
            fill="currentColor"
          />
        </svg>
        <span class="text-[8px]">menu.apetitomenu.com</span>
      </a>
      <div class="h-[3px] w-[90px] rounded-full bg-gray-900"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    restaurant() {
      return this.$page.props._restaurant;
    },
    enabledBranchesCount() {
      return this.$page.props._restaurant.branches.filter(
        ({ isEnabled }) => isEnabled,
      ).length;
    },
  },
  watch: {
    restaurant(
      { id: newId, updatedAt: newUpdatedAt },
      { id: oldId, updatedAt: oldUpdatedAt },
    ) {
      // Iframe will be reloaded automatically due to new src value.
      if (newId !== oldId) {
        return;
      }

      if (newUpdatedAt !== oldUpdatedAt) {
        this.$refs.iframe.contentWindow.postMessage('MenuUpdated', '*');
      }
    },
  },
};
</script>
