<template>
  <DashboardWrapper title="Team members">
    <ACard>
      <ACardHeader>
        Users
        <template #actions>
          <div>
            <AButton @click="modals.inviteUser = true"> Invite user </AButton>
          </div>
        </template>
      </ACardHeader>
      <div class="mb-3">
        Users below have access to
        <strong>{{ $page.props._restaurant.name }}.</strong>
      </div>
      <ATable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Last active</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <p class="font-semibold">{{ user.name }}</p>
              <p class="text-gray-500">{{ user.email }}</p>
            </td>
            <td>
              <p class="text-gray-500">
                <template v-if="!user.lastActivityAt"> Never </template>
                <template
                  v-else-if="
                    $luxon.DateTime.fromISO(user.lastActivityAt).diff(
                      $luxon.DateTime.now(),
                      'seconds',
                    ).seconds > -3
                  "
                >
                  Just now
                </template>
                <template v-else>
                  {{
                    $luxon.DateTime.fromISO(user.lastActivityAt).toRelative()
                  }}
                </template>
              </p>
            </td>
            <td>
              <div class="flex items-center text-gray-500">
                <div>
                  {{
                    roles.find(({ role }) => role === user.role)?.label ||
                    user.role
                  }}
                </div>

                <button
                  v-if="
                    user.role !== 'owner' && user.id !== $page.props._user.id
                  "
                  v-tippy="{ content: 'Change role' }"
                  type="button"
                  class="p-1"
                  @click="modals.changeUserRole = user"
                >
                  <PencilSimpleBold class="h-4 w-4 text-lime-500" />
                </button>
              </div>
            </td>
            <td>
              <button
                v-if="user.role !== 'owner' && user.id !== $page.props._user.id"
                v-tippy="{ content: 'Delete' }"
                type="button"
                class="p-1 text-gray-400 transition hover:text-red-500"
                @click="modals.deleteUser = user"
              >
                <TrashBold class="h-4 w-4" />
              </button>
            </td>
          </tr>
        </tbody>
      </ATable>

      <div class="mt-5 text-sm italic text-gray-500">
        If you want to transfer ownership to other user,
        <Link href="/support" class="underline">contact us</Link>.
      </div>

      <InviteUserModal
        v-if="modals.inviteUser"
        :roles="roles"
        :permissions="permissions"
        @close="modals.inviteUser = false"
      />

      <ChangeUserRoleModal
        v-if="modals.changeUserRole"
        :user="modals.changeUserRole"
        :roles="roles"
        :permissions="permissions"
        @close="modals.changeUserRole = false"
      />

      <DeleteUserModal
        v-if="modals.deleteUser"
        :user="modals.deleteUser"
        @close="modals.deleteUser = false"
      />
    </ACard>

    <ACard class="mt-4">
      <ACardHeader> Roles </ACardHeader>

      <ATable>
        <thead>
          <tr>
            <th class="w-16"></th>
            <th v-for="role in roles" :key="role.role" class="w-10">
              {{ role.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(permission, permissionIndex) in permissions"
            :key="permissionIndex"
          >
            <td>{{ permission.label }}</td>
            <td v-for="role in roles" :key="role.role">
              {{ permission.roles.includes(role.role) ? '✅' : '❌' }}
            </td>
          </tr>
        </tbody>
      </ATable>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AButton from '@app/Shared/AButton.vue';
import InviteUserModal from '@app/Modals/InviteUserModal.vue';
import ACardHeader from '@app/Shared/ACardHeader.vue';
import DeleteUserModal from '@app/Modals/DeleteUserModal.vue';
import ChangeUserRoleModal from '@app/Modals/ChangeUserRoleModal.vue';
import { Link } from '@inertiajs/vue3';
import ATable from '@app/Shared/ATable.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';

export default {
  components: {
    TrashBold,
    PencilSimpleBold,
    ATable,
    Link,
    ChangeUserRoleModal,
    DeleteUserModal,
    ACardHeader,
    InviteUserModal,
    AButton,
    ACard,
    DashboardWrapper,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        inviteUser: false,
        deleteUser: false,
        changeUserRole: false,
      },
      roles: [
        {
          role: 'owner',
          label: 'Owner',
          available: false,
        },
        {
          role: 'super_admin',
          label: 'Super Admin',
          available: true,
        },
        {
          role: 'admin',
          label: 'Admin',
          available: true,
        },
        {
          role: 'accountant',
          label: 'Accountant',
          available: true,
        },
      ],
      permissions: [
        {
          label: 'Restaurant settings',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'Menus',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'QR code',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'Feedback forms',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'Analytics',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'Tablet menu',
          roles: ['owner', 'super_admin', 'admin'],
        },
        {
          label: 'Team management',
          roles: ['owner', 'super_admin'],
        },
        {
          label: 'Subscription',
          roles: ['owner', 'super_admin', 'accountant'],
        },
      ],
    };
  },
};
</script>
