<template>
  <div class="scrollbar flex space-x-0.5 overflow-y-auto">
    <button
      v-for="language in languages"
      :key="language.code"
      type="button"
      class="flex items-center border-b-2 px-2 pb-1"
      :class="[
        selectedLanguageCode === language.code
          ? 'border-lime-500'
          : 'border-transparent text-gray-500 hover:text-gray-700',
      ]"
      @click="$emit('update:selectedLanguageCode', language.code)"
    >
      {{ language.name }}

      <svg
        v-if="hasErrors(language.code)"
        v-tippy="{ content: 'Has errors' }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        class="ml-1 h-4 w-4 text-red-500"
      >
        <path
          fill="currentColor"
          d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-8,56a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z"
        />
      </svg>
    </button>
    <button
      v-if="languages.length === 1"
      v-tippy="{ content: 'You can add languages in Restaurant Settings' }"
      class="border-b-2 border-transparent px-2 pb-1 text-gray-500 hover:text-gray-700"
    >
      +
    </button>
  </div>
</template>

<script>
import { getLanguageByCode } from '@/utils/language.js';

export default {
  props: {
    selectedLanguageCode: {
      type: String,
      required: true,
    },
  },
  emits: ['update:selectedLanguageCode'],
  computed: {
    languages() {
      const selectedLanguageCodes =
        this.$page.props._restaurant.availableLanguages;

      return selectedLanguageCodes.map((code) => getLanguageByCode(code));
    },
  },
  methods: {
    hasErrors(languageCode) {
      return Object.keys(this.$page.props.errors).some((key) =>
        key.endsWith(`.${languageCode}`),
      );
    },
  },
};
</script>
