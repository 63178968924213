<template>
  <FlashMessagesRenderer />
  <div class="flex min-h-screen bg-gray-50 pb-6 text-gray-700">
    <nav
      class="fixed inset-0 z-40 flex w-64 max-w-[calc(100%-3rem)] flex-col border-r border-gray-100 bg-white transition sm:static"
      :class="{ '-translate-x-full sm:translate-x-0': !sidebarExpanded }"
    >
      <div class="sticky top-0 h-screen overflow-y-auto px-4 py-5">
        <div class="mb-5 flex justify-between">
          <Link href="/">
            <img
              :src="'/images/logo-full.svg'"
              alt="Apetito Menu Logo"
              style="width: 175px"
            />
          </Link>

          <button
            type="button"
            class="-mr-3 -mt-3 flex h-10 w-10 shrink-0 items-center justify-center rounded bg-white text-gray-500 transition hover:text-gray-600 sm:hidden"
            @click="sidebarExpanded = false"
          >
            <XBold class="h-5 w-5 overflow-visible" />
          </button>
        </div>

        <div class="relative mb-5">
          <button
            type="button"
            class="flex w-full items-center rounded-md border border-gray-200 bg-white px-4 py-2 transition hover:bg-gray-50"
            @click="restaurantsMenuExpanded = true"
          >
            <span class="flex min-w-0 flex-1 flex-col items-start pr-3">
              <span class="block max-w-full truncate text-sm font-medium">
                {{ $page.props._restaurant.name }}
              </span>

              <span v-if="0" class="text-xs capitalize text-gray-500">{{
                $page.props._restaurant.user.role
              }}</span>
            </span>
            <CaretUpDownBold class="h-4 w-4" />
          </button>

          <Transition
            appear
            enter-active-class="duration-100 ease-out"
            enter-from-class="opacity-0 scale-y-95"
            enter-to-class="opacity-100 scale-y-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-y-100"
            leave-to-class="opacity-0 scale-y-95"
          >
            <div
              v-if="restaurantsMenuExpanded"
              v-click-outside="collapseRestaurantsMenu"
              class="absolute left-0 right-0 z-20 mt-2 flex origin-top flex-col rounded-md border border-gray-200 bg-white py-1"
            >
              <button
                v-for="restaurant in $page.props._user.restaurants"
                :key="restaurant.id"
                type="button"
                class="group flex items-center px-4 py-2 text-left text-sm font-medium transition hover:bg-lime-500"
                @click="switchToRestaurant(restaurant.id)"
              >
                <span
                  class="block flex-1 truncate transition group-hover:text-white"
                  >{{ restaurant.name }}</span
                >
                <span
                  v-if="$page.props._restaurant.id === restaurant.id"
                  class="pl-2"
                >
                  <CheckBold
                    class="h-4 w-4 text-lime-500 transition group-hover:text-white"
                  />
                </span>
                <span v-else-if="restaurant.hasIssue">
                  <WarningOctagonFill
                    class="h-4 w-4 text-red-500 transition group-hover:text-white"
                  />
                </span>
              </button>

              <div class="my-1 border-t border-gray-200"></div>

              <button
                type="button"
                class="group flex items-center px-4 py-2 text-left text-sm font-medium transition hover:bg-lime-500"
                @click="
                  () => {
                    modals.createRestaurant = true;
                    restaurantsMenuExpanded = false;
                  }
                "
              >
                <PlusBold class="mr-1 h-4 w-4 group-hover:text-white" />
                <span class="group-hover:text-white"> Add new restaurant </span>
              </button>
            </div>
          </Transition>
        </div>

        <div class="mb-5">
          <AButton
            variant="outline-primary"
            :href="$page.props._restaurant.menuUrl"
            target="_blank"
          >
            <template #icon><ArrowSquareOutBold /></template>
            View menu
          </AButton>
        </div>

        <template
          v-for="(navGroup, navGroupIndex) in navGroups"
          :key="navGroupIndex"
        >
          <div v-if="navGroupIndex > 0" class="h-3 w-full"></div>

          <NavLink
            v-for="(navItem, navItemIndex) in navGroup"
            :key="navItemIndex"
            :name="navItem.label"
            :href="navItem.href"
            :icon="navItem.icon"
            :current="navItem.current"
            @click="sidebarExpanded = false"
          />
        </template>
      </div>
    </nav>

    <Transition
      appear
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="sidebarExpanded"
        class="fixed inset-0 z-30 bg-black/20 backdrop-blur-sm sm:hidden"
        @mousedown="sidebarExpanded = false"
      ></div>
    </Transition>

    <div class="flex min-w-0 flex-1 flex-col">
      <div class="sticky top-0 z-20">
        <div
          v-if="
            ((trialEndsAt && !hasSubscription) ||
              (subscriptionCancelledAt && !hasSubscription) ||
              subscriptionRequiresAction) &&
            $page.url !== '/billing/available-plans'
          "
          class="flex justify-center py-2 px-3 text-sm"
          :class="[
            (isExpiredTrial && !hasSubscription) ||
            (subscriptionCancelledAt && !hasSubscription) ||
            subscriptionRequiresAction
              ? 'bg-red-500 text-white'
              : 'border-b border-yellow-100 bg-yellow-50 text-yellow-900',
          ]"
        >
          <p class="truncate">
            <template v-if="subscriptionRequiresAction">
              There is an issue with your subscription payment for
              {{ $page.props._restaurant.name }}.
              <strong>Take action to prevent menu deactivation.</strong>
            </template>
            <template v-else-if="subscriptionCancelledAt && !hasSubscription">
              Your subscription for {{ $page.props._restaurant.name }} was
              cancelled {{ subscriptionCancelledAt.toRelative() }}.
              <strong>People can't view your menu anymore.</strong>
            </template>
            <template v-else-if="isExpiredTrial && !hasSubscription">
              Your free trial for {{ $page.props._restaurant.name }} has ended
              {{ trialEndsAt.toRelative() }}.
              <strong>People can't view your menu anymore.</strong>
            </template>
            <template v-else-if="!hasSubscription">
              Your free trial for {{ $page.props._restaurant.name }} ends
              {{ trialEndsAt.toRelative() }}.
            </template>
          </p>
          <InertiaLink
            v-if="$page.props._restaurant.user.permissions.manageBilling"
            href="/billing"
            class="ml-1 whitespace-nowrap font-medium underline transition hover:opacity-90"
          >
            <template v-if="subscriptionRequiresAction">
              View details →
            </template>
            <template v-else> Choose plan → </template>
          </InertiaLink>
        </div>
        <div>
          <div
            class="flex h-full h-14 min-w-0 items-center rounded-t-none border-b border-gray-100 bg-white"
          >
            <div class="flex-1 pl-4">
              <button
                type="button"
                class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg border bg-white text-gray-500 shadow shadow-gray-100 transition hover:text-gray-600 sm:hidden"
                @click="sidebarExpanded = true"
              >
                <ListBold class="h-5 w-5 overflow-visible" />
              </button>
            </div>

            <div class="relative self-stretch">
              <button
                type="button"
                class="flex h-full items-center px-6 py-2 transition hover:bg-gray-50"
                @click="userMenuExpanded = true"
              >
                <img
                  class="h-8 w-8 rounded-full"
                  :src="$page.props._user.avatar"
                />
                <span class="pl-2 pr-1 text-sm font-medium">{{
                  $page.props._user.name
                }}</span>
                <CaretDownBold class="h-4 w-4" />
              </button>

              <Transition
                appear
                enter-active-class="duration-100 ease-out"
                enter-from-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="duration-100 ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
              >
                <div
                  v-if="userMenuExpanded"
                  v-click-outside="collapseUserMenu"
                  class="absolute right-2 z-10 mt-2 w-48 origin-top-right rounded-md border border-gray-200 bg-white py-1 shadow-lg"
                >
                  <a
                    v-for="(userMenuItem, userMenuItemIndex) in $page.props
                      ._user.userMenuItems"
                    :key="userMenuItemIndex"
                    :href="userMenuItem.url"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100"
                  >
                    {{ userMenuItem.name }}
                  </a>
                  <button
                    type="button"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100"
                    @click="modals.changePassword = true"
                  >
                    Change password
                  </button>
                  <button
                    type="button"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100"
                    @click="logout"
                  >
                    Logout
                  </button>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>

      <div class="relative flex min-w-0 flex-1">
        <main class="flex-1 overflow-x-auto px-4 pt-4 pb-16">
          <slot />
        </main>
        <aside
          v-if="$page.props._iframePreviewUrl"
          class="hidden shrink-0 py-4 pl-4 pr-8 lg:block"
        >
          <div class="mb-4 text-2xl font-bold">Preview</div>
          <PhonePreview />
        </aside>
      </div>
    </div>

    <CreateRestaurantModal
      v-if="modals.createRestaurant"
      @close="modals.createRestaurant = false"
    />

    <ChangePasswordModal
      v-if="modals.changePassword"
      @close="modals.changePassword = false"
    />
  </div>
</template>

<script>
import { Link, useForm } from '@inertiajs/vue3';
import AButton from './AButton.vue';
import NavLink from '@app/Shared/NavLink.vue';
import CreateRestaurantModal from '@app/Modals/CreateRestaurantModal.vue';
import FlashMessagesRenderer from '@app/Shared/FlashMessagesRenderer.vue';
import ChangePasswordModal from '@app/Modals/ChangePasswordModal.vue';
import PhonePreview from '@app/Shared/PhonePreview.vue';
import axios from 'axios';
import ForkKnifeRegular from '@/phosphoricons/ForkKnifeRegular.vue';
import QrCodeRegular from '@/phosphoricons/QrCodeRegular.vue';
import ShoppingCartRegular from '@/phosphoricons/ShoppingCartRegular.vue';
import ChartLineUpRegular from '@/phosphoricons/ChartLineUpRegular.vue';
import DeviceTabletRegular from '@/phosphoricons/DeviceTabletRegular.vue';
import UsersThreeRegular from '@/phosphoricons/UsersThreeRegular.vue';
import CreditCardRegular from '@/phosphoricons/CreditCardRegular.vue';
import GraduationCapRegular from '@/phosphoricons/GraduationCapRegular.vue';
import LifebuoyRegular from '@/phosphoricons/LifebuoyRegular.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import ListBold from '@/phosphoricons/ListBold.vue';
import XBold from '@/phosphoricons/XBold.vue';
import ArrowSquareOutBold from '@/phosphoricons/ArrowSquareOutBold.vue';
import CaretUpDownBold from '@/phosphoricons/CaretUpDownBold.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';
import CheckBold from '@/phosphoricons/CheckBold.vue';
import StorefrontRegular from '@/phosphoricons/StorefrontRegular.vue';
import ChatTextRegular from '@/phosphoricons/ChatTextRegular.vue';
import WarningOctagonFill from '@/phosphoricons/WarningOctagonFill.vue';

export default {
  components: {
    WarningOctagonFill,
    CheckBold,
    PlusBold,
    CaretUpDownBold,
    ArrowSquareOutBold,
    XBold,
    ListBold,
    CaretDownBold,
    PhonePreview,
    ChangePasswordModal,
    FlashMessagesRenderer,
    CreateRestaurantModal,
    NavLink,
    AButton,
    Link,
  },
  data() {
    return {
      userMenuExpanded: false,
      restaurantsMenuExpanded: false,
      logoutForm: useForm({}),
      switchRestaurantForm: useForm({
        restaurantId: null,
      }),
      modals: {
        createRestaurant: false,
        changePassword: false,
      },
      sidebarExpanded: false,
    };
  },
  computed: {
    trialEndsAt() {
      return this.$page.props._restaurant.trialEndsAt
        ? this.$luxon.DateTime.fromISO(this.$page.props._restaurant.trialEndsAt)
        : null;
    },
    subscriptionCancelledAt() {
      return this.$page.props._restaurant.subscriptionCancelledAt
        ? this.$luxon.DateTime.fromISO(
            this.$page.props._restaurant.subscriptionCancelledAt,
          )
        : null;
    },
    subscriptionRequiresAction() {
      return this.$page.props._restaurant.subscriptionRequiresAction;
    },
    hasSubscription() {
      return this.$page.props._restaurant.hasSubscription;
    },
    isExpiredTrial() {
      return this.trialEndsAt && this.$luxon.DateTime.now() > this.trialEndsAt;
    },
    navGroups() {
      const { permissions } = this.$page.props._restaurant.user;

      return [
        [
          {
            label: 'Restaurant settings',
            href: '/restaurant',
            icon: StorefrontRegular,
            current: this.getURL().pathname === '/restaurant',
            available: permissions.manageMenu,
          },
          {
            label: 'Menus',
            href: '/menus',
            icon: ForkKnifeRegular,
            current:
              this.getURL().pathname === '/menus' ||
              this.getURL().pathname === '/menus/create',
            available: permissions.manageMenu,
          },
          ...this.$page.props._restaurant.branches.map((menu) => ({
            label: this.$getPrimaryTranslation(menu.name),
            href: `/menus/${menu.id}`,
            current: this.getURL().pathname === `/menus/${menu.id}`,
            available: permissions.manageMenu,
          })),
          {
            label: 'QR code',
            href: '/dine-in-qr-menu',
            icon: QrCodeRegular,
            current: this.getURL().pathname === '/dine-in-qr-menu',
            available: permissions.manageMenu,
          },
        ],
        [
          {
            label: 'Orders',
            href: '/orders',
            icon: ShoppingCartRegular,
            current: this.getURL().pathname.startsWith('/orders'),
            available: permissions.manageOrders,
          },
        ],
        [
          {
            label: 'Feedback forms',
            href: '/forms',
            icon: ChatTextRegular,
            current: this.getURL().pathname === '/forms',
            available: permissions.manageMenu,
          },
          {
            label: 'Analytics',
            href: '/analytics',
            icon: ChartLineUpRegular,
            current: this.getURL().pathname === '/analytics',
            available: permissions.manageMenu,
          },
          {
            label: 'Tablet menu',
            href: '/tablet-menu',
            icon: DeviceTabletRegular,
            current: this.getURL().pathname === '/tablet-menu',
            available: permissions.manageMenu,
          },
        ],
        [
          {
            label: 'Team members',
            href: '/users',
            icon: UsersThreeRegular,
            current: this.getURL().pathname === '/users',
            available: permissions.manageUsers,
          },
          {
            label: 'Subscription',
            href: '/billing',
            icon: CreditCardRegular,
            current: this.getURL().pathname.startsWith('/billing'),
            available: permissions.manageBilling,
          },
        ],
        [
          {
            label: 'How it works',
            href: '/how-it-works',
            icon: GraduationCapRegular,
            current: this.getURL().pathname === '/how-it-works',
            available: permissions.manageMenu,
          },
          {
            label: 'Support',
            href: '/support',
            icon: LifebuoyRegular,
            current: this.getURL().pathname === '/support',
            available: true,
          },
        ],
      ]
        .map((navGroup) => navGroup.filter((navItem) => navItem.available))
        .filter((navGroup) => navGroup.length > 0);
    },
  },
  mounted() {
    window.addEventListener('focus', this.onWindowFocus);
  },
  unmounted() {
    window.removeEventListener('focus', this.onWindowFocus);
  },
  methods: {
    onWindowFocus() {
      if (this.$page.props._restaurant) {
        axios.put('/ajax/current-restaurant', {
          restaurantId: this.$page.props._restaurant.id,
        });
      }
    },
    collapseUserMenu() {
      this.userMenuExpanded = false;
    },
    collapseRestaurantsMenu() {
      this.restaurantsMenuExpanded = false;
    },
    getURL() {
      return new URL(location.href);
    },
    async logout() {
      await this.logoutForm.post('/logout');
    },
    async switchToRestaurant(restaurantId) {
      this.collapseRestaurantsMenu();
      this.switchRestaurantForm.restaurantId = restaurantId;
      await this.switchRestaurantForm.post('/switch-restaurant', {
        preserveState: false,
      });
    },
  },
};
</script>
