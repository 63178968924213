<template>
  <DashboardWrapper title="Change Plan" back-href="/billing">
    <ACard>
      <div>
        If you want to change the plan please,
        <Link href="/support" class="underline">contact us</Link>.
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import { Link } from '@inertiajs/vue3';
export default {
  components: { Link, ACard, DashboardWrapper },
};
</script>
