<template>
  <DashboardWrapper title="Restaurant settings">
    <RestaurantSettingsTabs />

    <ACard>
      <ACardHeader>
        <template #description>
          This information will be displayed publicly in your menu.
        </template>
      </ACardHeader>
      <form class="space-y-4" @submit.prevent="submit">
        <AInput
          v-model="form.description"
          :rows="4"
          type="textarea"
          label="Short restaurant description"
          placeholder="Describe your restaurant in a few words..."
          :error="form.errors.description"
        />

        <AInput
          v-model="form.openingHours"
          :rows="2"
          type="textarea"
          label="Opening hours"
          :placeholder="`Daily\n2:00 PM - 2:00 AM (last order - 1:30 AM)`"
          :error="form.errors.openingHours"
        />

        <AInput
          v-model="form.contactNumber"
          type="text"
          label="Contact number"
          placeholder="04 123 4567"
          :error="form.errors.contactNumber"
        />

        <AInput
          v-model="form.address"
          :rows="2"
          type="textarea"
          label="Address"
          placeholder="The Dubai Mall - Lower Ground - Financial Center Rd - Dubai"
          :error="form.errors.address"
        />

        <AInput
          v-model="form.email"
          type="text"
          label="Contact email"
          placeholder="support@apetitomenu.com"
          :error="form.errors.email"
        />

        <AInput
          v-model="form.website"
          type="text"
          label="Website"
          placeholder="https://example.com"
          :error="form.errors.website"
        />

        <AInput
          v-model="form.instagram"
          type="text"
          label="Instagram"
          placeholder="https://instagram.com/apetitomenu"
          :error="form.errors.instagram"
        />

        <AInput
          v-model="form.tiktok"
          type="text"
          label="TikTok"
          placeholder="https://www.tiktok.com/@apetitomenu"
          :error="form.errors.tiktok"
        />

        <AInput
          v-model="form.facebook"
          type="text"
          label="Facebook"
          placeholder="https://facebook.com/apetitomenu"
          :error="form.errors.facebook"
        />

        <AInput
          v-model="form.whatsapp"
          type="text"
          label="WhatsApp"
          placeholder="https://wa.me/971520000000"
          :error="form.errors.whatsapp"
        />

        <AInput
          v-model="form.googleReviewsUrl"
          type="text"
          label="Google Reviews URL"
          placeholder=""
          :error="form.errors.googleReviewsUrl"
        />

        <AInput
          v-model="form.tripadvisorUrl"
          type="text"
          label="TripAdvisor URL"
          placeholder=""
          :error="form.errors.tripadvisorUrl"
        />

        <div
          class="sticky bottom-0 z-10 flex w-full items-center bg-white py-3"
        >
          <AButton type="submit" class="mt-3" :loading="form.processing">
            Save
          </AButton>
          <div v-if="form.isDirty" class="ml-3 text-gray-400">
            (has unsaved changes)
          </div>
        </div>
      </form>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import AInput from '@app/Shared/AInput.vue';
import { useForm } from '@inertiajs/vue3';
import AButton from '@app/Shared/AButton.vue';
import ACard from '@app/Shared/ACard.vue';
import RestaurantSettingsTabs from '@app/Shared/Tabs/RestaurantSettingsTabs.vue';
import ACardHeader from '@app/Shared/ACardHeader.vue';

export default {
  components: {
    ACardHeader,
    ACard,
    AButton,
    AInput,
    DashboardWrapper,
    RestaurantSettingsTabs,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
    restaurant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        description: this.restaurant.description ?? '',
        address: this.restaurant.address ?? '',
        contactNumber: this.restaurant.contactNumber ?? '',
        website: this.restaurant.website ?? '',
        email: this.restaurant.email ?? '',
        instagram: this.restaurant.instagram ?? '',
        tiktok: this.restaurant.tiktok ?? '',
        facebook: this.restaurant.facebook ?? '',
        whatsapp: this.restaurant.whatsapp ?? '',
        openingHours: this.restaurant.openingHours ?? '',
        googleReviewsUrl: this.restaurant.googleReviewsUrl ?? '',
        tripadvisorUrl: this.restaurant.tripadvisorUrl ?? '',
      }),
    };
  },
  methods: {
    submit() {
      this.form.put('/restaurant', {
        preserveScroll: true,
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        onSuccess: () => {
          this.$toast.success('Saved');
        },
      });
    },
  },
};
</script>
