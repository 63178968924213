<template>
  <AuthLayout title="Log in">
    <form @submit.prevent="login">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Welcome back 👋
      </h1>
      <p class="mt-2 text-center text-gray-500">
        Log in to your Apetito Menu account to continue
      </p>

      <div class="mt-6 space-y-4">
        <SocialLoginButtons action="Login" />

        <div class="relative my-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-white px-2 text-gray-500">
              Or log in with your email
            </span>
          </div>
        </div>

        <div>
          <AInput
            v-model="form.email"
            label="Email"
            placeholder="Email"
            :error="form.errors.email"
            autocomplete="email"
          />
        </div>

        <div>
          <AInput
            v-model="form.password"
            label="Password"
            placeholder="Password"
            :error="form.errors.password"
            type="password"
            autocomplete="current-password"
          />
        </div>
      </div>

      <div class="mt-4 flex justify-end">
        <InertiaLink href="/forgot-password" class="text-lime-500"
          >Forgot password?</InertiaLink
        >
      </div>

      <div class="mt-4">
        <button
          type="submit"
          class="btn btn-primary w-full"
          :disabled="form.processing"
          :data-loading="form.processing"
        >
          Log in
        </button>
      </div>
    </form>

    <div class="mt-6 text-center text-gray-500">
      Don't have an account?
      <InertiaLink href="/register" class="text-lime-500">Register</InertiaLink>
    </div>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';
import SocialLoginButtons from '@app/Pages/App/Auth/SocialLoginButtons.vue';

export default {
  components: {
    SocialLoginButtons,
    AInput,
    AuthLayout,
  },
  data() {
    return {
      form: useForm({
        email: '',
        password: '',
      }),
    };
  },
  watch: {
    'form.email'() {
      this.form.clearErrors('email');
    },
    'form.password'() {
      this.form.clearErrors('password');
    },
  },
  methods: {
    async login() {
      await this.form.post('/login');
    },
  },
};
</script>
