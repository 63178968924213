<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.148 511.903C109.168 511.54 98.9857 509.927 93.1267 508.474C71.9538 503.19 53.4476 492.903 37.6487 477.694C15.2635 456.151 2.29307 428.074 0.393963 397.051C-0.131321 388.499 -0.131321 120.914 0.393963 114.177C2.21225 91.0609 9.28338 70.8094 21.971 52.6961C26.6177 46.0397 29.6886 42.409 36.1537 35.9544C45.3663 26.7565 54.0941 20.2212 64.5594 14.6541C79.1865 6.86814 92.3994 2.79365 109.895 0.574866C116.199 -0.191622 395.205 -0.191622 402.034 0.574866C427.288 3.5198 449.996 13.2421 468.624 29.0156C473.472 33.1708 482.079 42.0459 485.635 46.6449C500.504 65.7667 508.99 86.9057 511.333 110.788C512.222 119.865 512.222 392.251 511.333 401.206C509.273 421.781 502.686 440.418 491.777 456.797C486.524 464.623 483.412 468.456 476.786 475.233C457.108 495.323 433.47 507.062 404.256 511.258C399.771 511.903 389.387 511.944 258.389 511.984C180.849 512.024 116.36 511.984 115.148 511.903Z"
      fill="#00C6B6"
    />
    <path
      d="M319.391 99L304.578 237.474L279.29 120.718L199.995 137.282L225.283 254.011L111 277.849L131.175 371.094L332.153 413L378.129 311.683L400 107.321L319.391 99ZM247.482 277.064C248.94 277.039 250.385 277.334 251.715 277.928C254.449 279.144 259.075 281.722 259.995 285.673C261.349 291.351 260.048 296.127 255.894 299.855V299.881C251.754 303.597 246.273 303.257 240.464 301.36C234.668 299.463 232.091 292.568 234.286 284.234C235.916 278.045 243.618 277.13 247.482 277.064ZM301.923 284.234C307.798 284.077 312.858 286.667 315.724 291.338C318.576 296.009 317.13 301.151 314.12 306.292H314.094C311.084 311.421 303.185 312.114 295.404 308.386C290.16 305.861 290.186 299.437 290.764 295.525C291.068 293.384 291.944 291.364 293.301 289.677C295.167 287.361 298.308 284.338 301.923 284.234Z"
      fill="white"
    />
  </svg>
</template>
