<template>
  <div class="flex flex-wrap gap-x-2 gap-y-2">
    <InertiaLink
      class="btn btn-outline-secondary flex-1 whitespace-nowrap !text-gray-600"
      href="/login/google/redirect"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 h-4 w-4"
      >
        <path
          d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
          fill="#4285F4"
        />
        <path
          d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.12504 19.25 6.47505 17.14 5.52505 14.29H1.54504V17.38C3.51504 21.3 7.56504 24 12.255 24Z"
          fill="#34A853"
        />
        <path
          d="M5.52501 14.2901C5.27501 13.5701 5.145 12.8001 5.145 12.0001C5.145 11.2001 5.28501 10.4301 5.52501 9.71012V6.62012H1.545C0.725004 8.24012 0.255005 10.0601 0.255005 12.0001C0.255005 13.9401 0.725004 15.7601 1.545 17.3801L5.52501 14.2901Z"
          fill="#FBBC05"
        />
        <path
          d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.56504 0 3.51504 2.7 1.54504 6.62L5.52505 9.71C6.47505 6.86 9.12504 4.75 12.255 4.75Z"
          fill="#EA4335"
        />
      </svg>

      {{ action }} with Google
    </InertiaLink>

    <InertiaLink
      class="btn btn-outline-secondary flex-1 whitespace-nowrap !text-gray-600"
      href="/login/microsoft/redirect"
    >
      <svg
        class="mr-2 h-4 w-4"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.3999 0H0V11.3999H11.3999V0Z" fill="#F25022" />
        <path d="M24.0014 0H12.6016V11.3999H24.0014V0Z" fill="#7FBA00" />
        <path d="M11.3999 12.5996H0V23.9995H11.3999V12.5996Z" fill="#00A4EF" />
        <path
          d="M24.0014 12.5996H12.6016V23.9995H24.0014V12.5996Z"
          fill="#FFB900"
        />
      </svg>

      {{ action }} with Microsoft
    </InertiaLink>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
  },
};
</script>
