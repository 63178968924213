<template>
  <ATabs>
    <ATab
      href="/restaurant?tab=general"
      :current="
        getURL().pathname === '/restaurant' &&
        getURL().searchParams.get('tab') === 'general'
      "
    >
      General
    </ATab>
    <ATab
      href="/restaurant?tab=appearance"
      :current="
        getURL().pathname === '/restaurant' &&
        getURL().searchParams.get('tab') === 'appearance'
      "
    >
      Appearance
    </ATab>
    <ATab
      href="/restaurant?tab=about"
      :current="
        getURL().pathname === '/restaurant' &&
        getURL().searchParams.get('tab') === 'about'
      "
    >
      About
    </ATab>
  </ATabs>
</template>

<script>
import ATabs from '@app/Shared/ATabs.vue';
import ATab from '@app/Shared/ATab.vue';
export default {
  components: { ATab, ATabs },
  methods: {
    getURL() {
      return new URL(location.href);
    },
  },
};
</script>
