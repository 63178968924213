<template>
  <AModal @close="$emit('close')">
    <form class="space-y-4" @submit.prevent="submit">
      <ACardTitle>Change user role</ACardTitle>

      <AInput :model-value="user.name" label="Name" disabled />

      <AInput :model-value="user.email" label="Email" type="email" disabled />

      <ASelect
        v-model="form.role"
        label="New role"
        :options="availableRoles"
        :value-resolver="(option) => option.role"
        :text-resolver="(option) => option.label"
        empty-item-text="Choose role..."
        empty-item-disabled
        :error="form.errors.role"
      />

      <div v-if="form.role">
        <div class="mb-1">This role has the following access:</div>
        <div
          v-for="(permission, permissionIndex) in permissions"
          :key="permissionIndex"
          class="text-sm"
        >
          {{ permission.roles.includes(form.role) ? '✅' : '❌' }}
          {{ permission.label }}
        </div>
      </div>

      <div class="mt-3 flex space-x-2">
        <AButton type="submit" class="mt-3" :loading="form.processing">
          Change role
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </form>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';

export default {
  components: {
    ACardTitle,
    AModal,
    ASelect,
    AButton,
    AInput,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        role: '',
      }),
    };
  },
  computed: {
    availableRoles() {
      return this.roles.filter(({ available }) => available);
    },
  },
  methods: {
    async submit() {
      await this.form.put(`/users/${this.user.id}`, {
        onSuccess: () => {
          this.$emit('close');
          // this.$toast.success("User invited");
        },
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        preserveScroll: true,
      });
    },
  },
};
</script>
