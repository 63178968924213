<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.148 511.903C109.168 511.54 98.9857 509.927 93.1267 508.474C71.9538 503.19 53.4476 492.903 37.6487 477.694C15.2635 456.151 2.29307 428.074 0.393963 397.051C-0.131321 388.499 -0.131321 120.914 0.393963 114.177C2.21225 91.0609 9.28338 70.8094 21.971 52.6961C26.6177 46.0397 29.6886 42.409 36.1537 35.9544C45.3663 26.7565 54.0941 20.2212 64.5594 14.6541C79.1865 6.86814 92.3994 2.79365 109.895 0.574866C116.199 -0.191622 395.205 -0.191622 402.034 0.574866C427.288 3.5198 449.996 13.2421 468.624 29.0156C473.472 33.1708 482.079 42.0459 485.635 46.6449C500.504 65.7667 508.99 86.9057 511.333 110.788C512.222 119.865 512.222 392.251 511.333 401.206C509.273 421.781 502.686 440.418 491.777 456.797C486.524 464.623 483.412 468.456 476.786 475.233C457.108 495.323 433.47 507.062 404.256 511.258C399.771 511.903 389.387 511.944 258.389 511.984C180.849 512.024 116.36 511.984 115.148 511.903Z"
      fill="#FF5A00"
    />
    <path
      d="M292.737 412.462C255.361 410.324 223.804 383.214 215.601 346.302C213.46 336.7 213.379 333.473 213.338 283.369V243.511H198.994C183.559 243.511 180.932 243.269 176.084 241.293C167.598 237.864 160.85 229.674 158.79 220.355C158.345 218.338 158.022 194.093 158.466 193.69C158.749 193.407 162.184 193.206 171.316 192.883C181.054 192.56 188.65 192.278 192.731 192.076C194.63 191.995 200.045 191.794 204.772 191.632L213.379 191.35L213.298 168.517C213.258 152.824 213.379 144.796 213.702 142.859C215.278 133.46 219.561 125.432 226.834 118.251C231.481 113.652 235.319 110.868 241.138 107.964C249.341 103.849 257.381 101.832 269.22 100.904C283.282 99.8149 287.929 99.4922 289.828 99.4922H291.727V144.392V189.252H308.617C324.699 189.252 325.628 189.292 328.982 190.14C340.174 193.004 347.932 201.112 350.155 212.247C350.437 213.82 351.367 220.88 352.134 227.98C352.902 235.08 353.67 241.494 353.791 242.22L353.993 243.511H322.84H291.727V280.384C291.727 319.999 291.686 319.353 293.828 325.808C295.565 331.133 300.818 339 305.788 343.639C311.284 348.803 317.102 351.143 325.264 351.506C331.608 351.788 335.77 351.264 342.356 349.368C343.003 349.166 343.043 350.537 343.043 374.541V399.875L340.134 401.569C325.749 409.759 309.667 413.43 292.737 412.462Z"
      fill="white"
    />
  </svg>
</template>
