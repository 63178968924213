<template>
  <DashboardWrapper title="Analytics">
    <AnalyticsTabs v-if="$page.props._restaurant.branches.length > 1" />

    <ACard class="mb-4">
      <div>
        <div class="flex flex-wrap">
          <div
            class="mr-3 mb-2 flex items-center rounded border border-gray-300 px-3 py-1"
          >
            <CalendarBlankRegular class="mr-2 h-4 w-4 text-gray-500" />
            {{ rangeStartFormatted }} -
            {{ rangeEndFormatted }}
          </div>

          <DatePicker v-model="range" is-range>
            <template #default="{ togglePopover }">
              <button
                type="button"
                class="flex items-center rounded bg-lime-500 px-3 py-1 text-white transition hover:bg-lime-600"
                @click="togglePopover"
              >
                Change range
              </button>
            </template>
          </DatePicker>
        </div>
        <div class="mt-1 text-sm text-gray-500">
          Timezone: {{ $page.props._user.timezone }}
        </div>
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <ACardTitle>Menu Views</ACardTitle>
      </div>

      <div>
        Your menu has been viewed
        <span class="font-semibold"
          >{{ menuVisitsCount.toLocaleString() }}
          {{ menuVisitsCount === 1 ? 'time' : 'times' }}</span
        >
        between <span class="font-semibold">{{ rangeStartFormatted }}</span> and
        <span class="font-semibold">{{ rangeEndFormatted }}</span
        >.
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <ACardTitle>Top Visitor Device Languages</ACardTitle>
      </div>

      <p class="mb-4">
        Menu views per preferred language detected from devices of your
        visitors:
      </p>

      <div class="max-w-lg overflow-hidden rounded-lg border">
        <div
          v-for="(
            popularLanguage, popularLanguageIndex
          ) in preparedMostPopularLanguages"
          :key="popularLanguageIndex"
          class="flex items-center justify-between px-3 py-2 odd:bg-gray-50"
        >
          <div class="flex items-center">
            {{ popularLanguage.languageName }}

            <div
              class="relative ml-1.5 flex items-center rounded-full border px-2 font-medium"
              :class="[
                isPopularLanguageWithWarning(popularLanguage)
                  ? 'border-yellow-300 bg-yellow-50 text-yellow-800'
                  : 'border-gray-300 bg-gray-50',
              ]"
            >
              {{
                popularLanguage.percents > 0 ? popularLanguage.percents : '<1'
              }}%

              <template v-if="isPopularLanguageWithWarning(popularLanguage)">
                <WarningFill class="ml-1 h-4 w-4" />

                <div
                  v-tippy="{
                    content: `${popularLanguage.percents}% of your visitors prefer this language, but your menu is not translated to it`,
                  }"
                  class="absolute inset-0"
                />
              </template>
            </div>
          </div>
          <div>{{ popularLanguage.count.toLocaleString() }} views</div>
        </div>
      </div>

      <div
        v-if="
          preparedMostPopularLanguages.filter(isPopularLanguageWithWarning)
            .length > 0
        "
        class="mt-4 max-w-lg"
      >
        <AAlert
          type="warning"
          :actions="[
            {
              label: 'Update language settings',
              callback: () => {
                $inertia.get('/restaurant?tab=general');
              },
            },
          ]"
        >
          <template #title>
            {{
              preparedMostPopularLanguages
                .filter(isPopularLanguageWithWarning)
                .map((popularLanguage) => popularLanguage.languageName)
                .join(' and ')
            }}

            {{
              preparedMostPopularLanguages.filter(isPopularLanguageWithWarning)
                .length === 1
                ? 'is'
                : 'are'
            }}

            preferred by

            {{
              sumBy(
                preparedMostPopularLanguages.filter(
                  isPopularLanguageWithWarning,
                ),
                'percents',
              )
            }}% of your visitors
          </template>

          <template #description>
            Add
            {{
              preparedMostPopularLanguages.filter(isPopularLanguageWithWarning)
                .length === 1
                ? 'this language'
                : 'these languages'
            }}
            in settings and your menu will be
            <span class="font-medium">automatically translated</span>, helping
            you engage more visitors.
          </template>
        </AAlert>
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <ACardTitle>Top Viewed Products</ACardTitle>
      </div>
      <div
        v-if="mostViewedProducts.length > 0"
        class="max-w-lg overflow-hidden rounded-lg border"
      >
        <div
          v-for="mostViewedProduct in mostViewedProducts"
          :key="mostViewedProduct.product.id"
          class="flex items-center justify-between px-3 py-2 odd:bg-gray-50"
        >
          <div class="flex items-center">
            <div class="mr-2 shrink-0">
              <img
                v-if="mostViewedProduct.product.image"
                class="h-6 w-6 rounded-full"
                :src="mostViewedProduct.product.image"
                alt=""
              />
            </div>
            <div>
              {{ $getPrimaryTranslation(mostViewedProduct.product.name) }}
            </div>
          </div>
          <div>
            {{ mostViewedProduct.views }}
          </div>
        </div>
      </div>
      <div v-else>None of your products have been viewed yet.</div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';
import AnalyticsTabs from '@app/Shared/Tabs/AnalyticsTabs.vue';
import CalendarBlankRegular from '@/phosphoricons/CalendarBlankRegular.vue';
import { getLanguageByCode } from '@/utils/language';
import sumBy from 'lodash/sumBy';
import WarningFill from '@/phosphoricons/WarningFill.vue';
import AAlert from '@/app/Shared/AAlert.vue';

export default {
  components: {
    AAlert,
    WarningFill,
    CalendarBlankRegular,
    AnalyticsTabs,
    ACardTitle,
    ACard,
    DashboardWrapper,
    DatePicker,
  },
  props: {
    menuVisitsCount: {
      type: Number,
      required: true,
    },
    mostViewedProducts: {
      type: Array,
      required: true,
    },
    mostPopularLanguages: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    rangeStart: {
      type: String,
      required: true,
    },
    rangeEnd: {
      type: String,
      required: true,
    },
  },
  computed: {
    rangeStartFormatted() {
      return DateTime.fromISO(this.rangeStart).toLocaleString(
        DateTime.DATE_MED,
      );
    },
    rangeEndFormatted() {
      return DateTime.fromISO(this.rangeEnd).toLocaleString(DateTime.DATE_MED);
    },
    range: {
      get() {
        return {
          start: DateTime.fromISO(this.rangeStart).toJSDate(),
          end: DateTime.fromISO(this.rangeEnd).toJSDate(),
        };
      },
      set({ start, end }) {
        this.$inertia.reload({
          data: {
            filters: {
              ...this.filters,
              range_start: DateTime.fromJSDate(start).toISODate(),
              range_end: DateTime.fromJSDate(end).toISODate(),
            },
          },
        });
      },
    },
    preparedMostPopularLanguages() {
      return this.mostPopularLanguages.slice(0, 10).map((popularLanguage) => ({
        languageCode: popularLanguage.language,
        languageName: getLanguageByCode(popularLanguage.language)
          ? getLanguageByCode(popularLanguage.language).name
          : popularLanguage.language,
        count: popularLanguage.count,
        percents: Math.floor(
          (popularLanguage.count / sumBy(this.mostPopularLanguages, 'count')) *
            100,
        ),
      }));
    },
    popularLanguagePercentThreshold() {
      return 10;
    },
  },
  methods: {
    sumBy,
    isPopularLanguageWithWarning(popularLanguage) {
      if (
        this.$page.props._restaurant.availableLanguages.includes(
          popularLanguage.languageCode,
        )
      ) {
        return false;
      }

      if (popularLanguage.percents < this.popularLanguagePercentThreshold) {
        return false;
      }

      return true;
    },
  },
};
</script>
