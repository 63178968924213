<template>
  <ACard class="mb-4">
    <div>
      <div>
        <div class="flex justify-between">
          <div>
            <div class="mb-1 flex items-center">
              <div class="text-lg font-semibold">
                Order #{{ order.orderNumber }}
              </div>
              <div
                v-if="order.customerTable"
                class="ml-3 rounded-lg border border-dashed border-gray-300 bg-gray-100 px-2 py-0.5 font-semibold"
              >
                Table {{ order.customerTable }}
              </div>
              <div class="relative ml-3 mr-2">
                <button
                  type="button"
                  class="relative flex items-center rounded-lg border py-0.5 pl-3 pr-2 font-semibold"
                  :class="{
                    'border-yellow-400 bg-yellow-100 text-yellow-700':
                      orderStatusOption.type === 'warning',
                    'border-green-400 bg-green-100 text-green-700':
                      orderStatusOption.type === 'success',
                    'border-red-400 bg-red-100 text-red-700':
                      orderStatusOption.type === 'danger',
                  }"
                  @click="statusDropdownExpanded = true"
                >
                  <span class="relative mr-2 flex h-[10px] w-[10px]">
                    <span
                      class="absolute inline-flex h-full w-full rounded-full opacity-75"
                      :class="{
                        'animate-ping bg-yellow-400':
                          orderStatusOption.type === 'warning',
                        'bg-green-400': orderStatusOption.type === 'success',
                        'bg-red-400': orderStatusOption.type === 'danger',
                      }"
                    />
                    <span
                      class="relative inline-flex h-[10px] w-[10px] rounded-full"
                      :class="{
                        'bg-yellow-500': orderStatusOption.type === 'warning',
                        'bg-green-500': orderStatusOption.type === 'success',
                        'bg-red-500': orderStatusOption.type === 'danger',
                      }"
                    ></span>
                  </span>

                  <span>
                    {{ orderStatusOption.label }}
                  </span>

                  <CaretDownBold class="ml-1 h-4 w-4" />
                </button>

                <Transition
                  appear
                  enter-active-class="duration-100 ease-out"
                  enter-from-class="opacity-0 scale-y-95"
                  enter-to-class="opacity-100 scale-y-100"
                  leave-active-class="duration-100 ease-in"
                  leave-from-class="opacity-100 scale-y-100"
                  leave-to-class="opacity-0 scale-y-95"
                >
                  <div
                    v-if="statusDropdownExpanded"
                    v-click-outside="onStatusDropdownClickOutside"
                    class="absolute z-10 mt-2 overflow-hidden rounded-lg border bg-white shadow-lg"
                  >
                    <template
                      v-for="(statusOption, statusOptionIndex) in statusOptions"
                      :key="statusOptionIndex"
                    >
                      <button
                        v-if="
                          statusOption.available &&
                          statusOption.status !== order.status
                        "
                        type="button"
                        class="flex w-full items-center whitespace-nowrap border-b px-3 py-2 text-sm font-semibold transition last:border-b-0 hover:bg-gray-50"
                        @click="openUpdateStatusModal(statusOption.status)"
                      >
                        <span
                          class="relative mr-2 inline-flex h-[10px] w-[10px] rounded-full"
                          :class="{
                            'bg-yellow-500': statusOption.type === 'warning',
                            'bg-green-500': statusOption.type === 'success',
                            'bg-red-500': statusOption.type === 'danger',
                          }"
                        ></span>
                        <span> {{ statusOption.label }}</span>
                      </button>
                    </template>
                  </div>
                </Transition>
              </div>
            </div>

            <div v-if="order.rejectionReason" class="mb-4">
              <strong>Rejection reason:</strong>
              {{ order.rejectionReason }}
            </div>

            <div>
              <strong>Received:</strong>
              {{ $formatDateTime(order.createdAt, 'MMM d, hh:mm a') }}
            </div>

            <div v-if="order.customerName || order.customerPhone">
              <strong>Customer:</strong>
              {{
                [order.customerName, order.customerPhone]
                  .filter(Boolean)
                  .join(' - ')
              }}
            </div>

            <div v-if="order.typeName">
              <strong>Type: </strong>
              {{ order.typeName }}
            </div>

            <div v-if="order.paymentTypeName">
              <strong>Payment type: </strong>
              {{ order.paymentTypeName }}
            </div>

            <div v-if="order.onlinePaymentStatus">
              <strong>Online payment status:</strong>
              <span
                class="ml-2 rounded-full px-3 py-1 font-semibold"
                :class="{
                  'bg-green-100 text-green-600':
                    order.onlinePaymentStatus === 'PAID',
                  'animate-pulse bg-red-100 text-red-600':
                    order.onlinePaymentStatus === 'UNPAID',
                }"
              >
                <template v-if="order.onlinePaymentStatus === 'PAID'"
                  >Paid online
                </template>
                <template v-else-if="order.onlinePaymentStatus === 'UNPAID'"
                  >Waiting for online payment
                </template>
                <template v-else>
                  {{ order.onlinePaymentStatus }}
                </template>
              </span>
            </div>

            <div>
              <strong>Total:</strong>
              {{ order.currencyCode }}
              {{ $formatPrice(order.totalPrice) }}
              <span> ({{ order.totalQuantity }} items)</span>
            </div>
            <div class="mt-2 flex flex-wrap space-x-2">
              <div>
                <AButton
                  variant="outline-primary"
                  @click="modals.previewOrder = order"
                >
                  <template #icon><ArrowCircleRightBold /></template>
                  Details
                </AButton>
              </div>

              <div>
                <AButton variant="outline-primary" @click="printReceipt(order)">
                  <template #icon><PrinterBold /></template>
                  Print
                </AButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PreviewOrderModal
      v-if="modals.previewOrder"
      :order="modals.previewOrder"
      @close="modals.previewOrder = false"
    />

    <AModal v-if="modals.changeStatus" @close="modals.changeStatus = false">
      <form @submit.prevent="updateStatus">
        <div>
          Are you sure you want to change status to
          <strong>{{ getStatusOption(changeStatusForm.status).label }}</strong
          >?
        </div>

        <div v-if="changeStatusForm.status === 'REJECTED'" class="mt-4">
          <AInput
            v-model="changeStatusForm.rejectionReason"
            label="Rejection reason for customer"
            placeholder="Item out of stock"
            :error="changeStatusForm.errors['rejectionReason']"
            mandatory
          />
        </div>

        <div class="mt-4 flex flex-wrap">
          <button
            type="submit"
            class="btn btn-sm mr-2"
            :class="
              changeStatusForm.status === 'REJECTED'
                ? 'btn-danger'
                : 'btn-primary'
            "
            :disabled="changeStatusForm.processing"
            :data-loading="changeStatusForm.processing"
          >
            Change to {{ getStatusOption(changeStatusForm.status).label }}
          </button>

          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            @click="modals.changeStatus = false"
          >
            Go back
          </button>
        </div>
      </form>
    </AModal>
  </ACard>
</template>
<script>
import ACard from '@app/Shared/ACard.vue';
import PreviewOrderModal from '@app/Modals/PreviewOrderModal.vue';
import AModal from '@app/Shared/AModal.vue';
import AInput from '@app/Shared/AInput.vue';
import { useForm } from '@inertiajs/vue3';
import AButton from '@app/Shared/AButton.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import ArrowCircleRightBold from '@/phosphoricons/ArrowCircleRightBold.vue';
import PrinterBold from '@/phosphoricons/PrinterBold.vue';

export default {
  components: {
    PrinterBold,
    ArrowCircleRightBold,
    CaretDownBold,
    AButton,
    AInput,
    AModal,
    PreviewOrderModal,
    ACard,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        previewOrder: false,
        changeStatus: false,
      },
      changeStatusForm: useForm({
        status: '',
        rejectionReason: '',
      }),
      statusDropdownExpanded: false,
    };
  },
  computed: {
    orderStatusOption() {
      return this.getStatusOption(this.order.status);
    },
    statusOptions() {
      return [
        {
          status: 'NEW',
          label: 'New',
          type: 'warning',
          available: true,
        },
        {
          status: 'ACCEPTED',
          label: 'Accepted',
          type: 'success',
          available: true,
        },
        {
          status: 'READY_FOR_PICKUP',
          label: 'Ready for pickup',
          type: 'success',
          available:
            this.order.type === 'PICKUP' && this.order.status !== 'NEW',
        },
        {
          status: 'OUT_FOR_DELIVERY',
          label: 'Out for delivery',
          type: 'success',
          available:
            this.order.type === 'DELIVERY' && this.order.status !== 'NEW',
        },
        {
          status: 'COMPLETED',
          label: 'Completed',
          type: 'success',
          available: this.order.status !== 'NEW',
        },
        {
          status: 'REJECTED',
          label: 'Rejected',
          type: 'danger',
          available: true,
        },
      ];
    },
  },
  methods: {
    getStatusOption(status) {
      return (
        this.statusOptions.find((s) => s.status === status) ?? {
          status: this.order.status,
          label: this.order.status,
          color: 'warning',
        }
      );
    },
    openUpdateStatusModal(status) {
      if (this.order.status !== status) {
        this.changeStatusForm.clearErrors();
        this.changeStatusForm.status = status;
        this.changeStatusForm.rejectionReason = '';
        this.modals.changeStatus = true;
      }

      this.statusDropdownExpanded = false;
    },
    async updateStatus() {
      await this.changeStatusForm.put(`/orders/${this.order.id}/status`, {
        preserveScroll: true,
        onSuccess: () => {
          this.modals.changeStatus = false;
        },
      });
    },
    printReceipt(order) {
      window.open(order.receiptUrl);
    },
    onStatusDropdownClickOutside() {
      this.statusDropdownExpanded = false;
    },
  },
};
</script>
