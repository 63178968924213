<template>
  <DashboardWrapper
    :title="$getPrimaryTranslation(branch.name)"
    :back-href="'/menus'"
  >
    <MenuManagementTabs :branch-id="branch.id" />
    <ACard v-show="categories.length === 0">
      <ACardHeader>
        Let's start building your menu!

        <template #description>
          You're almost there. Choose how you want to add your menu items below.
        </template>
      </ACardHeader>
      <MenuCreator :branch="branch" />
      <div class="mt-4 text-sm text-gray-500">
        Need some help?
        <Link href="/support" class="underline">Contact us</Link> anytime.
      </div>
    </ACard>
    <ACard v-if="categories.length > 0">
      <ACardHeader>
        <template #actions>
          <AButton
            v-if="categories.length > 0"
            @click="
              () => {
                defaultCategoryId = null;
                modals.createProduct = true;
              }
            "
          >
            <template #icon><PlusBold /></template>
            Add product
          </AButton>

          <AButton @click="modals.createCategory = true">
            <template #icon><PlusBold /></template>
            Add category
          </AButton>
        </template>
      </ACardHeader>

      <div v-if="categories.length > 0" class="mb-4 flex flex-wrap">
        <div class="mr-3 mb-3">
          <AInput
            v-model="searchQuery"
            label="Search by name"
            placeholder="Search..."
          />
        </div>
      </div>

      <div v-if="categories.length === 0">
        <p>Your menu is empty.</p>
        <p class="mt-2">
          Add your first category using <strong>"Add category"</strong> button
          in the top right corner.
        </p>
      </div>

      <div v-else>
        <Draggable
          :list="categories"
          :item-key="(category) => category.id"
          handle=".category-draggable-handle"
          :animation="200"
          :delay="250"
          :delay-on-touch-only="true"
          @change="onCategoryDrag"
        >
          <template #item="{ element: category }">
            <div
              v-show="
                categoryMatchesSearchQuery(category) ||
                category.products.some((categoryProduct) =>
                  productMatchesSearchQuery(categoryProduct),
                )
              "
              class="mb-2 rounded border border-gray-200 bg-white"
            >
              <div
                class="flex flex-col justify-between py-3 pl-2 pr-2 sm:flex-row"
              >
                <div class="flex flex-1 items-center">
                  <button
                    v-tippy="{ content: 'Drag to reorder' }"
                    type="button"
                    class="category-draggable-handle flex h-8 w-8 cursor-move items-center justify-center rounded hover:bg-gray-100"
                  >
                    <DotsSixVerticalBold class="w-4 text-gray-500" />
                  </button>
                  <button
                    type="button"
                    class="flex h-8 w-8 items-center justify-center rounded enabled:hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-25"
                    :class="{
                      'rotate-180':
                        isCategoryExpanded(category.id) || hasSearchQuery,
                    }"
                    :disabled="hasSearchQuery"
                    @click="toggleCategory(category.id)"
                  >
                    <CaretDownBold class="w-4 text-gray-500" />
                  </button>

                  <div
                    class="flex flex-1 cursor-pointer items-center self-stretch"
                    @click="toggleCategory(category.id)"
                  >
                    <div class="ml-2 flex items-center">
                      <div
                        v-if="category.image"
                        class="mr-2 h-6 w-12 rounded-md bg-cover bg-center"
                        :style="{ backgroundImage: `url('${category.image}')` }"
                      />

                      <div class="font-bold text-gray-900">
                        {{ $getPrimaryTranslation(category.name) || '--' }}
                        <span
                          v-if="branch.isCategoryTypesNavbarEnabled"
                          class="font-normal text-gray-400"
                          >({{ category.type }})</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center justify-end space-x-2">
                  <div class="p-1">
                    <AToggle
                      v-tippy="{
                        content: category.isEnabled
                          ? 'Hide from menu'
                          : 'Show in menu',
                      }"
                      :model-value="category.isEnabled"
                      @update:model-value="
                        onCategoryIsEnabledUpdate({
                          category,
                          isEnabled: $event,
                        })
                      "
                    />
                  </div>
                  <button
                    v-tippy="{ content: 'Edit' }"
                    type="button"
                    class="p-1"
                    @click="modals.editCategory = category"
                  >
                    <PencilSimpleBold class="h-4 w-4 text-lime-500" />
                  </button>
                  <button
                    v-if="$page.props._restaurant.branches.length > 1"
                    v-tippy="{ content: 'Copy' }"
                    type="button"
                    class="p-1"
                    @click="modals.copyCategory = category"
                  >
                    <CopyBold class="h-4 w-4 text-lime-500" />
                  </button>
                  <button
                    v-tippy="{ content: 'Delete' }"
                    type="button"
                    class="p-1"
                    @click="modals.deleteCategory = category"
                  >
                    <TrashBold class="h-4 w-4 text-lime-500" />
                  </button>
                </div>
              </div>

              <div
                v-show="isCategoryExpanded(category.id) || hasSearchQuery"
                class="border-t border-gray-200"
              >
                <Draggable
                  :list="category.products"
                  :item-key="(product) => product.id"
                  handle=".product-draggable-handle"
                  :animation="200"
                  :delay="250"
                  :delay-on-touch-only="true"
                  @change="onProductDrag"
                >
                  <template #item="{ element: product }">
                    <div
                      v-show="
                        productMatchesSearchQuery(product) ||
                        categoryMatchesSearchQuery(category)
                      "
                      class="flex flex-col justify-between border-b border-dashed border-gray-200 bg-white py-3 pl-10 pr-2 sm:flex-row"
                    >
                      <div class="flex items-center">
                        <button
                          type="button"
                          class="product-draggable-handle flex h-8 w-8 cursor-move items-center justify-center rounded hover:bg-gray-100"
                        >
                          <DotsSixVerticalBold class="w-4 text-gray-500" />
                        </button>

                        <div class="flex items-center">
                          <div
                            class="ml-2 h-8 w-8 flex-shrink-0 overflow-hidden rounded bg-gray-200"
                          >
                            <img
                              class="h-8 w-8 object-cover"
                              :src="
                                product.image ||
                                '/images/menu_item_placeholder.png'
                              "
                              alt=""
                            />
                          </div>
                          <div class="ml-2">
                            <div class="text-sm font-medium text-gray-900">
                              {{ $getPrimaryTranslation(product.name) || '--' }}
                            </div>
                            <div class="text-xs text-gray-500">
                              Price:
                              {{
                                product.variants
                                  .map((variant) =>
                                    variant.price === null
                                      ? '--'
                                      : $formatPrice(variant.price),
                                  )
                                  .join(', ')
                              }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center justify-end space-x-2">
                        <div class="p-1">
                          <AToggle
                            v-tippy="{
                              content: product.isEnabled
                                ? 'Hide from menu'
                                : 'Show in menu',
                            }"
                            :model-value="product.isEnabled"
                            @update:model-value="
                              onProductIsEnabledUpdate({
                                product,
                                isEnabled: $event,
                              })
                            "
                          />
                        </div>
                        <button
                          v-tippy="{ content: 'Edit' }"
                          type="button"
                          class="p-1"
                          @click="modals.editProduct = product"
                        >
                          <PencilSimpleBold class="h-4 w-4 text-lime-500" />
                        </button>
                        <button
                          v-tippy="{ content: 'Delete' }"
                          type="button"
                          class="p-1"
                          @click="modals.deleteProduct = product"
                        >
                          <TrashBold class="h-4 w-4 text-lime-500" />
                        </button>
                      </div>
                    </div>
                  </template>
                </Draggable>

                <div class="pt-4 pb-4 pl-10">
                  <AButton
                    v-if="categories.length > 0"
                    variant="outline-primary"
                    @click="
                      () => {
                        defaultCategoryId = category.id;
                        modals.createProduct = true;
                      }
                    "
                  >
                    <template #icon><PlusBold /></template>
                    Add product
                  </AButton>
                </div>
              </div>
            </div>
          </template>
        </Draggable>
      </div>

      <CreateEditCategoryModal
        v-if="modals.createCategory"
        :categories="categories"
        :branch="branch"
        @close="modals.createCategory = false"
      />

      <CreateEditCategoryModal
        v-if="modals.editCategory"
        :existing-category="modals.editCategory"
        :categories="categories"
        :branch="branch"
        @close="modals.editCategory = false"
      />

      <DeleteCategoryModal
        v-if="modals.deleteCategory"
        :category="modals.deleteCategory"
        @close="modals.deleteCategory = false"
      />

      <CopyCategoryModal
        v-if="modals.copyCategory"
        :category="modals.copyCategory"
        @close="modals.copyCategory = false"
      />

      <CreateEditProductModal
        v-if="modals.createProduct"
        :categories="categories"
        :default-category-id="defaultCategoryId"
        @close="modals.createProduct = false"
      />

      <CreateEditProductModal
        v-if="modals.editProduct"
        :existing-product="modals.editProduct"
        :categories="categories"
        @close="modals.editProduct = false"
      />

      <DeleteProductModal
        v-if="modals.deleteProduct"
        :product="modals.deleteProduct"
        @close="modals.deleteProduct = false"
      />
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import AButton from '@app/Shared/AButton.vue';
import AToggle from '@app/Shared/AToggle.vue';
import DeleteCategoryModal from '@app/Modals/DeleteCategoryModal.vue';
import CreateEditCategoryModal from '@app/Modals/CreateEditCategoryModal.vue';
import ACard from '@app/Shared/ACard.vue';
import Draggable from 'vuedraggable';
import MenuManagementTabs from '@app/Shared/Tabs/MenuManagementTabs.vue';
import ACardHeader from '@app/Shared/ACardHeader.vue';
import CreateEditProductModal from '@app/Modals/CreateEditProductModal.vue';
import DeleteProductModal from '@app/Modals/DeleteProductModal.vue';
import AInput from '@app/Shared/AInput.vue';
import MenuCreator from '@app/Shared/MenuCreator.vue';
import CopyCategoryModal from '@app/Modals/CopyCategoryModal.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import CopyBold from '@/phosphoricons/CopyBold.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';
import DotsSixVerticalBold from '@/phosphoricons/DotsSixVerticalBold.vue';
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    Link,
    DotsSixVerticalBold,
    PlusBold,
    CaretDownBold,
    CopyBold,
    TrashBold,
    PencilSimpleBold,
    CopyCategoryModal,
    MenuCreator,
    AInput,
    DeleteProductModal,
    CreateEditProductModal,
    ACardHeader,
    MenuManagementTabs,
    ACard,
    CreateEditCategoryModal,
    DeleteCategoryModal,
    AToggle,
    AButton,
    DashboardWrapper,
    Draggable,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    branch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      modals: {
        createCategory: false,
        editCategory: false,
        deleteCategory: false,
        copyCategory: false,

        createProduct: false,
        editProduct: false,
        deleteProduct: false,
      },
      defaultCategoryId: null,
      expandedCategoryIds: {},
    };
  },
  computed: {
    columns() {
      return [
        { key: 'name', name: 'Category' },
        { key: 'actions', width: '125px' },
      ];
    },
    hasSearchQuery() {
      return this.searchQuery !== '';
    },
  },
  watch: {
    categories: {
      immediate: true,
      handler(categories) {
        if (categories.length === 0) {
          this.modals.deleteCategory = false;
        }

        categories.forEach((category) => {
          if (category.products.length === 0) {
            this.expandCategory(category.id);
          }
        });
      },
    },
  },
  methods: {
    categoryMatchesSearchQuery(category) {
      return this.matchesSearchQuery(category.name);
    },
    productMatchesSearchQuery(product) {
      return this.matchesSearchQuery(product.name);
    },
    matchesSearchQuery(translations) {
      if (!this.hasSearchQuery) {
        return true;
      }

      return Object.values(translations).some(
        (translation) =>
          translation &&
          translation.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !==
            -1,
      );
    },
    onCategoryIsEnabledUpdate({ category, isEnabled }) {
      category.isEnabled = isEnabled;

      this.$inertia.put(
        `/categories/${category.id}/availability`,
        {
          isEnabled,
        },
        {
          preserveScroll: true,
          except: ['branch', 'ingredientWarnings', 'modifierSets'],
        },
      );
    },
    onProductIsEnabledUpdate({ product, isEnabled }) {
      product.isEnabled = isEnabled;

      this.$inertia.put(
        `/products/${product.id}/availability`,
        {
          isEnabled,
        },
        {
          preserveScroll: true,
          except: ['branch', 'ingredientWarnings', 'modifierSets'],
        },
      );
    },
    onCategoryDrag() {
      this.$inertia.put(
        `/categories/order`,
        {
          newOrder: this.categories.map((category) => category.id),
        },
        {
          preserveScroll: true,
          except: ['branch', 'ingredientWarnings', 'modifierSets'],
        },
      );
    },
    onProductDrag({ moved }) {
      const category = this.categories.find(
        (c) => c.id === moved.element.categoryId,
      );

      this.$inertia.put(
        `/products/order`,
        {
          newOrder: category.products.map((product) => product.id),
        },
        {
          preserveScroll: true,
          except: ['branch', 'ingredientWarnings', 'modifierSets'],
        },
      );
    },
    isCategoryExpanded(categoryId) {
      return !!this.expandedCategoryIds[categoryId];
    },
    expandCategory(categoryId) {
      this.expandedCategoryIds[categoryId] = true;
    },
    collapseCategory(categoryId) {
      delete this.expandedCategoryIds[categoryId];
    },
    toggleCategory(categoryId) {
      if (this.isCategoryExpanded(categoryId)) {
        this.collapseCategory(categoryId);
      } else {
        this.expandCategory(categoryId);
      }
    },
  },
};
</script>
