<template>
  <div
    class="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
  >
    <div class="mx-auto max-w-max">
      <main class="text-center">
        <p class="text-base font-extrabold text-lime-600">
          {{ status }}
        </p>
        <div class="">
          <div class="">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
              {{ title }}
            </h1>
          </div>
          <div v-if="maintenance" class="mt-4 max-w-sm text-sm text-gray-500">
            We are currently performing scheduled maintenance to enhance your
            experience. Our admin panel will be back online shortly. Thank you
            for your patience.
          </div>
          <div v-if="!maintenance" class="mt-6 flex justify-center">
            <AButton href="/">Back to home</AButton>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import AButton from '@app/Shared/AButton.vue';
export default {
  components: { AButton },
  props: {
    status: {
      type: Number,
      required: true,
    },
    maintenance: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    title() {
      if (this.maintenance) {
        return 'Under maintenance';
      }

      switch (this.status) {
        case 404:
          return 'Not found';
        case 403:
          return 'Not available';
        default:
          return 'Something went wrong...';
      }
    },
  },
};
</script>
