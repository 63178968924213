<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div class="space-y-4">
      <ACardTitle>How would you like to name your menu?</ACardTitle>

      <AutoTranslateToggle
        v-model:auto-translate="form.autoTranslate"
        :selected-language-code="selectedLanguageCode"
      />

      <AInput
        v-model="form.name[selectedLanguageCode]"
        label="Menu name"
        placeholder="Business lunch"
        :error="form.errors[`name.${selectedLanguageCode}`]"
        :mandatory="isPrimaryLanguageSelected"
        :disabled="areTranslatableFieldsDisabled"
      />
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          Create menu
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';

export default {
  components: {
    AutoTranslateToggle,
    ATranslationTabs,
    ACardTitle,
    AModal,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: {},
        name: {},
      }),
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return ['form.name'];
    },
  },
  methods: {
    async submit() {
      await this.form.post('/menus', {
        onSuccess: () => {
          this.$emit('close');
        },
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        preserveScroll: true,
      });
    },
  },
};
</script>
