<template>
  <div class="-mx-2 flex px-2 pb-4">
    <div
      class="flex divide-x divide-gray-200 overflow-x-auto rounded-lg shadow"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>
