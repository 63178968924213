<template>
  <div>
    <ALabel v-if="label">
      {{ label }}
    </ALabel>
    <div class="mt-1">
      <div class="relative">
        <input
          ref="input"
          v-model="color"
          type="text"
          style="
            width: calc(0.75rem + 1.5em + 0.55rem + 5.3em);
            padding-left: calc(0.75rem + 1.5em + 0.55rem - 1px);
          "
          class="rounded-md border border-gray-300 font-mono focus:border-lime-500 focus:outline-none focus:ring-lime-500"
          @focus="expanded = true"
          @blur="valid ? null : (color = modelValue)"
          @keydown.tab="expanded = false"
        />

        <div
          class="pointer-events-none absolute bottom-0 left-0 top-0 flex items-center pl-[0.75rem] pr-[0.55rem]"
        >
          <span
            class="block h-[1.5em] w-[1.5em] shrink-0 rounded-full border border-gray-300"
            :style="{ 'background-color': modelValue }"
          />
        </div>
      </div>

      <Transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
        appear
      >
        <div
          v-if="expanded"
          v-click-outside="onClickOutside"
          style="width: 250px; height: 200px"
          class="absolute z-30 mt-2 shrink-0 rounded border border-gray-300 bg-white p-3 shadow-lg"
        >
          <hex-color-picker
            :color="color"
            class="h-full w-full shrink-0"
            @color-changed="color = $event.target.color"
          ></hex-color-picker>
        </div>
      </Transition>
    </div>
    <AError v-if="error" class="mt-1">
      {{ error }}
    </AError>
  </div>
</template>

<script>
import 'vanilla-colorful';
import ALabel from '@app/Shared/ALabel.vue';
import AError from '@app/Shared/AError.vue';

export default {
  components: { AError, ALabel },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      color: this.modelValue,
      expanded: false,
    };
  },
  computed: {
    valid() {
      return Boolean(this.color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/));
    },
  },
  watch: {
    color(color) {
      if (color.length > 0 && !color.startsWith('#')) {
        this.color = `#${color}`;
      }
      this.color = this.color.toLowerCase();
      if (this.valid) {
        this.$emit('update:modelValue', this.color);
      }
    },
    expanded() {
      this.color = this.modelValue;
    },
  },
  methods: {
    onClickOutside() {
      if (this.$refs.input !== document.activeElement) {
        this.expanded = false;
      }
    },
  },
};
</script>
