<template>
  <DashboardWrapper
    :title="$getPrimaryTranslation(branch.name)"
    :back-href="'/menus'"
  >
    <MenuManagementTabs :branch-id="branch.id" />
    <ACard class="mb-4">
      <ACardHeader>
        <template #actions>
          <AButton
            @click="
              () => {
                modals.createModifierSet = true;
              }
            "
          >
            <template #icon><PlusBold /></template>
            Add modifier set
          </AButton>
        </template>
      </ACardHeader>

      <div>
        <div
          v-for="modifierSet in modifierSets"
          :key="modifierSet.id"
          class="mb-2 rounded border border-gray-200 bg-white"
        >
          <div class="flex flex-col justify-between py-3 pl-2 pr-2 sm:flex-row">
            <div class="flex flex-1 items-center">
              <button
                type="button"
                class="flex h-8 w-8 items-center justify-center rounded enabled:hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-25"
                :class="{
                  'rotate-180': isModifierSetExpanded(modifierSet.id),
                }"
                @click="toggleModifierSet(modifierSet.id)"
              >
                <CaretDownBold class="w-4 text-gray-500" />
              </button>

              <div
                class="flex flex-1 cursor-pointer items-center self-stretch"
                @click="toggleModifierSet(modifierSet.id)"
              >
                <div class="ml-2 flex items-center">
                  <div class="font-bold text-gray-900">
                    {{ $getPrimaryTranslation(modifierSet.name) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-end space-x-2">
              <button
                v-tippy="{ content: 'Edit' }"
                type="button"
                class="p-1"
                @click="modals.editModifierSet = modifierSet"
              >
                <PencilSimpleBold class="h-4 w-4 text-lime-500" />
              </button>
              <button
                v-tippy="{ content: 'Delete' }"
                type="button"
                class="p-1"
                @click="modals.deleteModifierSet = modifierSet"
              >
                <TrashBold class="h-4 w-4 text-lime-500" />
              </button>
            </div>
          </div>

          <div
            v-show="isModifierSetExpanded(modifierSet.id)"
            class="border-t border-gray-200"
          >
            <div class="py-2 px-12">
              <div class="mb-2 text-sm text-gray-500">
                Min: {{ modifierSet.minSelection }}, Max:
                {{ modifierSet.maxSelection }}
              </div>

              <div class="font-semibold">
                {{ $getPrimaryTranslation(modifierSet.name) }}
              </div>
              <div v-for="modifier in modifierSet.modifiers" :key="modifier.id">
                • {{ $getPrimaryTranslation(modifier.name) }}
                <span v-if="modifier.price !== null" class="font-semibold">
                  +{{ $page.props._restaurant.currencyCode }}
                  {{ $formatPrice(modifier.price) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateEditModifierSetModal
        v-if="modals.createModifierSet"
        :branch="branch"
        @close="modals.createModifierSet = false"
      />

      <CreateEditModifierSetModal
        v-if="modals.editModifierSet"
        :branch="branch"
        :existing-modifier-set="modals.editModifierSet"
        @close="modals.editModifierSet = false"
      />

      <DeleteModifierSetModal
        v-if="modals.deleteModifierSet"
        :modifier-set="modals.deleteModifierSet"
        @close="modals.deleteModifierSet = false"
      />
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import MenuManagementTabs from '@app/Shared/Tabs/MenuManagementTabs.vue';
import DeleteModifierSetModal from '@app/Modals/DeleteModifierSetModal.vue';
import CreateEditModifierSetModal from '@app/Modals/CreateEditModifierSetModal.vue';
import AButton from '@app/Shared/AButton.vue';
import ACardHeader from '@app/Shared/ACardHeader.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';

export default {
  components: {
    PlusBold,
    CaretDownBold,
    TrashBold,
    PencilSimpleBold,
    ACardHeader,
    AButton,
    CreateEditModifierSetModal,
    DeleteModifierSetModal,
    MenuManagementTabs,
    ACard,
    DashboardWrapper,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
    modifierSets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createModifierSet: false,
        editModifierSet: false,
        deleteModifierSet: false,
      },
      expandedModifierSetIds: {},
    };
  },
  methods: {
    isModifierSetExpanded(modifierSetId) {
      return !!this.expandedModifierSetIds[modifierSetId];
    },
    expandModifierSet(modifierSetId) {
      this.expandedModifierSetIds[modifierSetId] = true;
    },
    collapseModifierSet(modifierSetId) {
      delete this.expandedModifierSetIds[modifierSetId];
    },
    toggleModifierSet(modifierSetId) {
      if (this.isModifierSetExpanded(modifierSetId)) {
        this.collapseModifierSet(modifierSetId);
      } else {
        this.expandModifierSet(modifierSetId);
      }
    },
  },
};
</script>
