<template>
  <AModal @close="$emit('close')">
    <div>
      <div class="mb-1 text-xl font-semibold">
        Order #{{ order.orderNumber }}
      </div>
      <div>
        <strong>Received:</strong>
        {{ $formatDateTime(order.createdAt, 'MMM d, hh:mm a') }}
      </div>
      <div
        v-for="(displayField, displayFieldIndex) in order.displayFields"
        :key="displayFieldIndex"
      >
        <strong>{{ displayField.name }}: </strong>
        {{ displayField.value }}
      </div>

      <div class="my-3">
        <hr />
      </div>

      <div>
        <div
          v-for="(orderItem, orderItemIndex) in order.orderItems"
          :key="orderItemIndex"
          class="mb-4 last:mb-0"
        >
          <p>
            <strong>{{ orderItem.quantity }}x</strong>
            {{ $getPrimaryTranslation(orderItem.productName) }}
            {{ $getPrimaryTranslation(orderItem.productVariantName) }}
          </p>

          <p
            v-for="(modifier, modifierIndex) in orderItem.modifiers"
            :key="modifierIndex"
          >
            - {{ $getPrimaryTranslation(modifier.modifierName) }}
          </p>

          <p>
            {{ order.currencyCode }}
            {{ $formatPrice(orderItem.price * orderItem.quantity) }}
          </p>
        </div>
      </div>

      <div class="my-3">
        <hr />
      </div>

      <div>
        <strong>Total:</strong>
        {{ order.currencyCode }}
        {{ $formatPrice(order.totalPrice) }}
      </div>

      <button
        type="button"
        class="btn btn-outline-primary btn-sm mt-4"
        @click="$emit('close')"
      >
        Go back
      </button>
    </div>
  </AModal>
</template>

<script>
import AModal from '@app/Shared/AModal.vue';

export default {
  components: { AModal },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
};
</script>
