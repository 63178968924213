<template>
  <AModal @close="$emit('close')">
    <form class="space-y-4" @submit.prevent="submit">
      <ACardTitle>Copy category</ACardTitle>

      <ASelect
        v-model="form.targetMenuId"
        label="Which menu do you want to copy the category to?"
        :options="$page.props._restaurant.branches"
        :value-resolver="(branch) => branch.id"
        :text-resolver="(branch) => $getPrimaryTranslation(branch.name)"
        empty-item-text="Choose menu..."
        empty-item-disabled
        :error="form.errors.targetMenuId"
      />

      <p class="text-gray-500">
        <strong>Note:</strong> The category and its products will be copied
        immediately, the images will be copied within a few minutes after that.
      </p>

      <div class="mt-3 flex space-x-2">
        <AButton type="submit" class="mt-3" :loading="form.processing">
          Copy category
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </form>
  </AModal>
</template>
<script>
import AButton from '@app/Shared/AButton.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';

export default {
  components: {
    ACardTitle,
    AModal,
    ASelect,
    AButton,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        sourceCategoryId: this.category.id,
        targetMenuId: null,
      }),
    };
  },
  methods: {
    async submit() {
      await this.form.post('/copy-category', {
        onSuccess: () => {
          this.$emit('close');
        },
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        preserveScroll: true,
      });
    },
  },
};
</script>
