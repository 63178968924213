<template>
  <AuthLayout title="Let us know more about your restaurant">
    <form @submit.prevent="submit">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Preview your menu
      </h1>

      <div class="mt-2 text-center text-sm text-gray-500">
        Time to see how your brand new menu looks like
      </div>

      <div class="mt-6 flex justify-center">
        <div
          class="flex max-w-[300px] flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200"
        >
          <div class="flex flex-col items-center justify-center px-6 pt-5">
            <div class="text-center text-lg font-medium">
              Scan the QR code below to view your menu
            </div>
            <AQRCode :value="menuUrl" class="mt-4" />
          </div>
          <div class="relative my-3 w-full">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div
                class="w-full border-t-2 border-dashed border-gray-200"
              ></div>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="bg-white px-2"> OR </span>
            </div>
          </div>
          <div class="flex justify-center px-6 pb-4">
            <a
              :href="menuUrl"
              target="_blank"
              class="btn btn-outline-primary btn-sm"
            >
              Click to open menu
            </a>
          </div>
        </div>
      </div>

      <div class="mt-6 space-y-4"></div>

      <div class="mt-8">
        <button
          type="submit"
          :disabled="form.processing"
          :data-loading="form.processing"
          class="btn btn-primary w-full"
        >
          Go to dashboard
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import celebrate from '@/utils/celebrate.js';
import AQRCode from '@app/Shared/AQRCode.vue';

export default {
  components: {
    AQRCode,
    AuthLayout,
  },
  props: {
    menuUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        //
      }),
    };
  },
  mounted() {
    celebrate();
  },
  methods: {
    async submit() {
      await this.form.post('/onboarding/preview', {
        onSuccess: () => {
          //
        },
      });
    },
  },
};
</script>
