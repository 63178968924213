import get from 'lodash/get';
import set from 'lodash/set';
import axios from 'axios';

export default {
  data() {
    return {
      selectedLanguageCode: this.$page.props._restaurant.languages[0],
    };
  },
  watch: {
    translationTriggerKey: {
      immediate: true,
      handler() {
        this.translateToLanguage(this.selectedLanguageCode);
      },
    },
  },
  computed: {
    translationTriggerKey() {
      return `${
        this.selectedLanguageCode
      }-${this.isAutoTranslateEnabledForLanguage(this.selectedLanguageCode)}`;
    },
    autoTranslatePath() {
      return null;
    },
    translatablePaths() {
      return [];
    },
    primaryLanguageCode() {
      return this.$page.props._restaurant.languages[0];
    },
    isPrimaryLanguageSelected() {
      return this.selectedLanguageCode === this.primaryLanguageCode;
    },
    areTranslatableFieldsDisabled() {
      if (this.isPrimaryLanguageSelected) {
        return false;
      }

      if (!this.isAutoTranslateEnabledForLanguage(this.selectedLanguageCode)) {
        return false;
      }

      return true;
    },
  },
  methods: {
    isAutoTranslateEnabledForLanguage(languageCode) {
      if (!this.autoTranslatePath) {
        return false;
      }

      return Boolean(
        get(this, `${this.autoTranslatePath}.${languageCode}`, true),
      );
    },
    async translateToLanguage(to) {
      const from = this.primaryLanguageCode;
      const translatablePaths = [...this.translatablePaths];

      if (to === from) {
        return;
      }

      if (!this.isAutoTranslateEnabledForLanguage(to)) {
        return;
      }

      const strings = translatablePaths
        .map((path) => get(this, `${path}.${from}`))
        .filter((string) => Boolean(string))
        .map((string) => string.trim());

      let translations = {};

      if (strings.length > 0) {
        translations = (
          await axios.post('/ajax/translate', {
            from,
            to,
            strings,
          })
        ).data.translations;
      }

      translatablePaths.forEach((path) => {
        const string = get(this, `${path}.${from}`);

        set(this, `${path}.${to}`, translations[string?.trim()] ?? '');
      });
    },
  },
};
