<template>
  <div>
    <ALabel v-if="label">
      {{ label }}
    </ALabel>
    <div class="h-1"><!-- todo: better solution --></div>
    <Multiselect
      v-model="selectedOptions"
      :options="preparedOptions"
      label="text"
      value-prop="value"
      mode="tags"
      :searchable="true"
      placeholder="Select"
    />
    <AError v-if="error" class="mt-1">
      {{ error }}
    </AError>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import ALabel from '@app/Shared/ALabel.vue';
import AError from '@app/Shared/AError.vue';

export default {
  components: {
    AError,
    ALabel,
    Multiselect,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    valueResolver: {
      type: Function,
      default: (option) => option['value'],
    },
    textResolver: {
      type: Function,
      default: (option) => option['text'],
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    selectedOptions: {
      get() {
        return this.modelValue;
      },
      set(selectedOptions) {
        this.$emit('update:modelValue', selectedOptions);
      },
    },
    preparedOptions() {
      return this.options.map((option) => ({
        value: this.valueResolver(option),
        text: this.textResolver(option),
      }));
    },
  },
};
</script>
