<template>
  <AuthLayout title="Create account">
    <form @submit.prevent="register">
      <h1 class="text-center text-2xl font-bold text-gray-600">Get started</h1>
      <p class="mt-2 text-center text-sm text-gray-500">
        Create your Apetito Menu account to get started with your menu
      </p>

      <div v-if="0" class="mt-2 text-center text-sm text-gray-500">
        Try for free. No credit card required.
      </div>

      <div class="mt-6 space-y-4">
        <SocialLoginButtons action="Register" />

        <div class="relative my-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-white px-2 text-gray-500">
              Or register with your email
            </span>
          </div>
        </div>

        <div>
          <AInput
            v-model="form.name"
            label="Full name"
            :error="form.errors.name"
            autocomplete="off"
            autocorrect="off"
          />
        </div>

        <div>
          <AInput
            v-model="form.email"
            label="Email"
            type="email"
            :error="form.errors.email"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
          />
        </div>

        <div>
          <AInput
            v-model="form.password"
            label="Password"
            :error="form.errors.password"
            type="password"
            autocapitalize="off"
            autocomplete="new-password"
            autocorrect="off"
          />
          <p class="mt-1 text-xs text-gray-500">
            Your password should be at least 8 characters.
          </p>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          :disabled="form.processing"
          :data-loading="form.processing"
          class="btn btn-primary w-full"
        >
          Register →
        </button>
      </div>
    </form>

    <div class="mt-3 text-center text-xs text-gray-500">
      By proceeding, you agree to the
      <a
        href="https://apetitomenu.com/terms-of-service"
        target="_blank"
        class="underline"
        >Terms of Service</a
      >
      and
      <a
        href="https://apetitomenu.com/privacy-policy"
        target="_blank"
        class="underline"
        >Privacy Policy</a
      >.
    </div>

    <div class="mt-6 text-center text-gray-500">
      Have an account?
      <InertiaLink href="/login" class="text-lime-500">Log in</InertiaLink>
    </div>

    <div v-if="0" class="relative my-6">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-center text-sm">
        <span class="bg-white px-2 text-gray-500"> or </span>
      </div>
    </div>

    <div v-if="0">
      <AButton variant="outline-primary" href="/login/google/redirect">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-4 w-4"
        >
          <path
            d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
            fill="#4285F4"
          />
          <path
            d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.12504 19.25 6.47505 17.14 5.52505 14.29H1.54504V17.38C3.51504 21.3 7.56504 24 12.255 24Z"
            fill="#34A853"
          />
          <path
            d="M5.52501 14.2901C5.27501 13.5701 5.145 12.8001 5.145 12.0001C5.145 11.2001 5.28501 10.4301 5.52501 9.71012V6.62012H1.545C0.725004 8.24012 0.255005 10.0601 0.255005 12.0001C0.255005 13.9401 0.725004 15.7601 1.545 17.3801L5.52501 14.2901Z"
            fill="#FBBC05"
          />
          <path
            d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.56504 0 3.51504 2.7 1.54504 6.62L5.52505 9.71C6.47505 6.86 9.12504 4.75 12.255 4.75Z"
            fill="#EA4335"
          />
        </svg>

        Register via Google
      </AButton>
    </div>
  </AuthLayout>
</template>

<script>
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';
import { DateTime } from 'luxon';
import SocialLoginButtons from '@app/Pages/App/Auth/SocialLoginButtons.vue';

export default {
  components: {
    SocialLoginButtons,
    AInput,
    AuthLayout,
    AButton,
  },
  data() {
    return {
      form: useForm({
        name: '',
        email: '',
        password: '',
        timezone: DateTime.now().zoneName,
      }),
    };
  },
  watch: {
    'form.name'() {
      this.form.clearErrors('name');
    },
    'form.email'() {
      this.form.clearErrors('email');
    },
    'form.password'() {
      this.form.clearErrors('password');
    },
  },
  methods: {
    async register() {
      await this.form.post('/register', {
        onSuccess: () => {
          window.fbq && window.fbq('track', 'StartTrial');

          window.gtag &&
            window.gtag('event', 'conversion', {
              send_to: 'AW-10942188799/lyHKCNTp46EYEP-Z0uEo',
            });

          // this.$toast.success(
          //   "A verification link has been sent to your email account."
          // );
          // Conversion Google Ads
          // window.gtag &&
          //   window.gtag("event", "conversion", {
          //     send_to: "AW-10942188799/0Sz2CLXhpeEDEP-Z0uEo",
          //   });
        },
      });
    },
  },
};
</script>
