<template>
  <slot :expanded="expanded" :toggle="() => (expanded = !expanded)" />
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
