<template>
  <AModal
    @close="
      () => {
        copyFromOtherPlatformForm.cancel();
        $emit('close');
      }
    "
  >
    <div v-if="copied" class="flex flex-col items-center text-center">
      <CheckCircleThin class="h-24 w-24 text-lime-500" />
      <div class="text-lg font-bold">Menu copied successfully!</div>
      <div class="mt-2 max-w-md text-gray-500">You can now edit your menu.</div>
      <button
        type="button"
        class="btn btn-primary btn-sm mt-6"
        @click="$emit('close')"
      >
        Open menu editor
      </button>
    </div>
    <form v-else @submit.prevent="submit">
      <ACardTitle> Copy menu from delivery platform </ACardTitle>

      <div class="mt-2 text-gray-500">
        <div>
          Our system can copy your menu from these platforms:
          <TalabatLogo
            v-tippy="{ content: 'Talabat' }"
            class="-mt-[2px] mr-1 inline h-4 w-4"
          />
          <DeliverooLogo
            v-tippy="{ content: 'Deliveroo' }"
            class="-mt-[2px] mr-1 inline h-4 w-4"
          />
        </div>
        <div class="mt-2">You can edit your menu anytime after copying.</div>
      </div>

      <div class="mt-6">
        <AInput
          v-model="copyFromOtherPlatformForm.url"
          type="url"
          label="Link to your menu on delivery platform"
          placeholder="https://deliveroo.ae/menu/Dubai/downtown-dubai-mall/starbucks-the-palace-cafe/?day=today&geohash=thrr3fse2k9v&time=ASAP"
          mandatory
          :error="copyFromOtherPlatformForm.errors['url']"
          :disabled="copyFromOtherPlatformForm.processing"
        />
      </div>

      <ProgressBar
        v-if="copyFromOtherPlatformForm.processing"
        :duration-ms="20000"
        class="mt-6"
      >
        <template #label="{ progress }">
          <template v-if="progress < 10">
            Connecting to {{ platformName }}...
          </template>
          <template v-else-if="progress < 30"> Fetching menu data... </template>
          <template v-else> Copying categories and items... </template>
        </template>
      </ProgressBar>

      <div class="mt-6 flex justify-between">
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm"
          :disabled="copyFromOtherPlatformForm.processing"
          @click="$emit('close')"
        >
          Back
        </button>

        <button
          type="submit"
          class="btn btn-primary btn-sm"
          :disabled="copyFromOtherPlatformForm.processing"
          :data-loading="copyFromOtherPlatformForm.processing"
        >
          {{ platformName ? 'Copy from ' + platformName : 'Copy' }}
        </button>
      </div>
    </form>
  </AModal>
</template>

<script>
import AModal from '@app/Shared/AModal.vue';
import AInput from '@app/Shared/AInput.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import startCase from 'lodash/startCase';
import { useForm } from '@inertiajs/vue3';
import TalabatLogo from '@app/Shared/TalabatLogo.vue';
import DeliverooLogo from '@app/Shared/DeliverooLogo.vue';
import ProgressBar from '@/app/Shared/ProgressBar.vue';
import CheckCircleThin from '@/phosphoricons/CheckCircleThin.vue';
import celebrate from '@/utils/celebrate';

export default {
  components: {
    CheckCircleThin,
    ProgressBar,
    DeliverooLogo,
    TalabatLogo,
    ACardTitle,
    AInput,
    AModal,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      copyFromOtherPlatformForm: useForm({
        url: '',
        branchId: this.branch.id,
      }),
      copied: false,
    };
  },
  computed: {
    platformName() {
      try {
        const url = new URL(this.copyFromOtherPlatformForm.url);

        const host = url.host;

        const eligibleHostParts = host
          .split('.')
          .slice(0, -1)
          .filter(
            (hostPart) => !['menu', 'qr', 'www', 'order'].includes(hostPart),
          );

        if (eligibleHostParts.length === 1) {
          return startCase(eligibleHostParts[0]);
        }
      } catch (e) {
        //
      }

      return null;
    },
  },
  watch: {
    'copyFromOtherPlatformForm.url'() {
      this.copyFromOtherPlatformForm.clearErrors('url');
    },
  },
  methods: {
    submit() {
      this.copyFromOtherPlatformForm.clearErrors();

      this.copyFromOtherPlatformForm.post('/copy-menu-from-other-platform', {
        onSuccess: () => {
          celebrate();

          this.copied = true;
        },
      });
    },
  },
};
</script>
