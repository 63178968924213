<template>
  <Component
    :is="isLink ? 'a' : 'button'"
    class="btn btn-sm"
    :data-loading="loading"
    :disabled="loading"
    :class="{
      'btn-primary': variant === 'primary',
      'btn-outline-primary': variant === 'outline-primary',
      'btn-outline-secondary': variant === 'outline-secondary',
      'btn-danger': variant === 'danger',
      'w-full': block,
    }"
    v-bind="attributes"
  >
    <div
      :class="{ invisible: loading }"
      class="flex items-center justify-center"
    >
      <div class="mr-1 h-4 w-4 shrink-0 empty:hidden"><slot name="icon" /></div>
      <slot />
    </div>
  </Component>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (type) =>
        ['primary', 'outline-primary', 'outline-secondary', 'danger'].includes(
          type,
        ),
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    isLink() {
      return this.href !== null;
    },
    attributes() {
      return this.isLink
        ? {
            href: this.href,
            target: this.target,
            onClick: (event) => {
              event.preventDefault();
              if (this.disabled || this.loading) {
                return;
              }
              if (event.metaKey || this.target === '_blank') {
                window.open(this.href);
              } else {
                this.$inertia.get(this.href);
              }
            },
          }
        : {
            type: this.type,
            disabled: this.disabled || this.loading,
            onClick: (event) => {
              if (this.disabled || this.loading) {
                event.preventDefault();
              }
              this.$emit('click');
            },
          };
    },
  },
};
</script>
