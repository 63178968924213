function getBenefits({ maxLanguages = 2, allowCustomHostname = false } = {}) {
  return {
    maxLanguages,
    allowCustomHostname,
  };
}

export default () => [
  {
    name: 'QR code menu',
    tooltip: 'QR code with unlimited scans for your menu.',
    isAvailable: () => true,
  },
  {
    name: 'Tablet menu',
    tooltip: 'Use your menu on unlimited tablets, even without internet.',
    isAvailable: () => true,
  },
  {
    name: 'Shareable menu link',
    tooltip:
      'Easily share your menu link on social media or directly with customers.',
    isAvailable: () => true,
  },
  {
    name: 'Easy to use menu editor',
    tooltip: 'Update your menu from any device, anytime.',
    isAvailable: () => true,
  },
  {
    name: 'Design customization',
    tooltip: 'Personalize your menu’s colors, layout, and fonts.',
    isAvailable: () => true,
  },
  {
    name: 'Online support',
    tooltip:
      'Chat with the founder to get answers about the platform and resolve technical issues.',
    isAvailable: () => true,
  },
  {
    name: 'Invite users',
    tooltip: 'Invite team members to manage your menu together.',
    isAvailable: () => true,
  },
  {
    name: 'Feedback forms',
    tooltip: 'Gather feedback directly from your menu.',
    isAvailable: () => true,
  },
  {
    name: 'Analytics',
    tooltip: 'Track menu views and most popular menu items.',
    isAvailable: () => true,
  },
  {
    name: 'Multi-language menu',
    tooltip: 'Auto-translate your menu, with easy manual edits.',
    isAvailable: (benefits) => getBenefits(benefits).maxLanguages > 1,
    getClarification: (benefits) =>
      getBenefits(benefits).maxLanguages === 1
        ? '1 language'
        : `${getBenefits(benefits).maxLanguages} languages`,
  },
  {
    name: 'Custom domain',
    tooltip: 'Use your custom domain for a branded menu link.',
    isAvailable: (benefits) => getBenefits(benefits).allowCustomHostname,
  },
];
