<template>
  <div>
    <Switch
      v-model="enabled"
      :class="enabled ? 'bg-lime-500' : 'bg-gray-200'"
      class="relative inline-flex h-[16px] w-[28px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
    >
      <span
        aria-hidden="true"
        :class="enabled ? 'translate-x-3' : 'translate-x-0'"
        class="pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
      />
    </Switch>
  </div>
</template>

<script>
import { Switch } from '@headlessui/vue';

export default {
  components: {
    Switch,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    enabled: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue);
      },
    },
  },
};
</script>
