<template>
  <div v-if="languages.length > 1">
    <div
      v-if="isPrimaryLanguage(selectedLanguageCode)"
      class="italic text-gray-500"
    >
      {{ textForPrimaryLanguage }}
    </div>
    <div v-else>
      <label class="flex cursor-pointer items-center">
        <AToggle v-model="enabled" />
        <span class="ml-2 font-medium text-gray-700">Auto translate</span>
      </label>
    </div>
  </div>
</template>

<script>
import AToggle from '@app/Shared/AToggle.vue';
import { getLanguageByCode } from '@/utils/language.js';

export default {
  components: { AToggle },
  props: {
    autoTranslate: {
      type: Object,
      required: true,
    },
    selectedLanguageCode: {
      type: String,
      required: true,
    },
  },
  emits: ['update:autoTranslate'],
  computed: {
    languages() {
      return this.$page.props._restaurant.languages;
    },
    enabled: {
      get() {
        return this.isEnabledForLanguage(this.selectedLanguageCode);
      },
      set(value) {
        this.$emit('update:autoTranslate', {
          ...this.autoTranslate,
          [this.selectedLanguageCode]: value,
        });
      },
    },
    languageName() {
      return getLanguageByCode(this.selectedLanguageCode).name;
    },
    textForPrimaryLanguage() {
      const languageNamesWithDisabledTranslations = this.languages
        .filter(
          (languageCode) =>
            !this.isPrimaryLanguage(languageCode) &&
            !this.isEnabledForLanguage(languageCode),
        )
        .map((languageCode) => getLanguageByCode(languageCode).name);

      let text = `Auto translate for details below`;

      if (languageNamesWithDisabledTranslations.length > 0) {
        text += ` is disabled for ${languageNamesWithDisabledTranslations.join(
          ', ',
        )}.`;
      } else {
        text += ` is enabled for all languages.`;
      }

      return text;
    },
  },
  methods: {
    isEnabledForLanguage(languageCode) {
      return Boolean(this.autoTranslate[languageCode] ?? true);
    },
    isPrimaryLanguage(languageCode) {
      return languageCode === this.languages[0];
    },
  },
};
</script>
