<template>
  <div class="relative flex overflow-x-auto">
    <table class="table">
      <slot />
    </table>

    <div
      class="sticky right-0 w-10 shrink-0 self-stretch bg-gradient-to-l from-white"
    ></div>
  </div>
</template>
<script setup></script>
