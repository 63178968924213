<template>
  <DashboardWrapper title="Thanks for choosing Apetito Menu! 🎉">
    <ACard class="mb-4">
      <div>
        It might take a few minutes for your subscription to be activated.
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import celebrate from '@/utils/celebrate';

export default {
  components: { ACard, DashboardWrapper },
  mounted() {
    celebrate();
  },
};
</script>
