<template>
  <Head>
    <title>{{ title }} - Apetito Menu</title>
  </Head>
  <FlashMessagesRenderer />
  <div class="flex min-h-screen flex-col items-center bg-gray-50 pt-8 pb-12">
    <a href="https://apetitomenu.com">
      <img
        :src="'/images/logo-full.svg'"
        alt="Apetito Menu Logo"
        class="w-[200px]"
      />
    </a>

    <div
      class="mt-8 w-full border border-gray-100/50 bg-white p-8 shadow-xl shadow-gray-200/50 sm:max-w-xl sm:rounded-xl sm:p-10"
    >
      <slot />
    </div>

    <button
      v-if="$page.props._user"
      type="button"
      class="mt-10 text-sm text-gray-400"
      @click="$inertia.post('/logout')"
    >
      Logout
    </button>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import FlashMessagesRenderer from '@app/Shared/FlashMessagesRenderer.vue';

export default {
  components: { FlashMessagesRenderer, Head },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
