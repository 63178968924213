<template>
  <DashboardWrapper title="Ordering settings" back-href="/orders">
    <OrderTypesCard :order-types="orderTypes" />
    <OrderPaymentTypesCard
      :order-payment-types="orderPaymentTypes"
      class="mt-8"
    />
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import OrderTypesCard from '@/app/Shared/Cards/OrderTypesCard.vue';
import OrderPaymentTypesCard from '@/app/Shared/Cards/OrderPaymentTypesCard.vue';

export default {
  components: {
    OrderPaymentTypesCard,
    OrderTypesCard,
    DashboardWrapper,
  },
  props: {
    orderTypes: {
      type: Array,
      required: true,
    },
    orderPaymentTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
