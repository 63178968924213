<template>
  <AuthLayout title="Let us know more about your restaurant">
    <form @submit.prevent="submit">
      <p class="text-center text-gray-500">
        Hello, {{ $page.props._user.name }}!
      </p>

      <h1 class="text-center text-2xl font-bold text-gray-600">
        Let's add your first restaurant
      </h1>

      <div class="mt-8 space-y-4">
        <div>
          <AInput
            v-model="form.restaurantName"
            label="What is your restaurant's name?"
            type="text"
            :error="form.errors.restaurantName"
            autocomplete="off"
            autocorrect="off"
            placeholder="e.g., Ocean View Café"
          />

          <p class="mt-1 text-xs text-gray-500">
            You can change it later in the settings.
          </p>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          :disabled="form.processing"
          :data-loading="form.processing"
          class="btn btn-primary w-full"
        >
          Add restaurant →
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';
import celebrate from '@/utils/celebrate';

export default {
  components: {
    AInput,
    AuthLayout,
  },
  data() {
    return {
      form: useForm({
        restaurantName: '',
      }),
    };
  },
  watch: {
    'form.restaurantName'() {
      this.form.clearErrors('restaurantName');
    },
  },
  methods: {
    submit() {
      this.form.post('/onboarding/restaurant', {
        onSuccess: () => {
          celebrate();
        },
      });
    },
  },
};
</script>
