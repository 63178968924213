<template>
  <div class="flex items-center">
    <div
      v-tippy="{
        content: feature.tooltip,
        placement: 'right',
        maxWidth: '200px',
      }"
      class="cursor-help text-sm"
    >
      {{ feature.name.split(' ').slice(0, -1).join(' ') }}
      <span class="whitespace-nowrap">
        {{ feature.name.split(' ').slice(-1).join(' ') }}

        <InfoRegular class="mb-[2px] inline h-4 w-4" />
      </span>
    </div>
  </div>
</template>

<script>
import InfoRegular from '@/phosphoricons/InfoRegular.vue';

export default {
  components: { InfoRegular },
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
};
</script>
