<template>
  <AuthLayout title="Tell us about yourself">
    <form @submit.prevent="submit">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Tell us about yourself
      </h1>

      <div class="mt-2 text-center text-sm text-gray-500">Step 2 of 3</div>

      <div class="mt-6 space-y-4">
        <div v-if="0">
          <AInput
            v-model="form.name"
            label="Your name"
            type="text"
            :error="form.errors.name"
            autocomplete="name"
            placeholder="Enter your name"
          />
        </div>

        <div>
          <AInput
            v-model="form.phoneNumber"
            label="What is your phone number?"
            type="tel"
            :error="form.errors.phoneNumber"
            autocomplete="tel"
          />
          <p class="mt-1 text-xs text-gray-500">
            We need it to better support you and help with your menu.
          </p>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          :disabled="form.processing"
          :data-loading="form.processing"
          class="btn btn-primary w-full"
        >
          Continue
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';
import { DateTime } from 'luxon';

export default {
  components: {
    AInput,
    AuthLayout,
  },
  props: {
    phonePrefix: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: useForm({
        name: '',
        phoneNumber: '',
        timezone: DateTime.now().zoneName,
      }),
    };
  },
  watch: {
    'form.name'() {
      this.form.clearErrors('name');
    },
    'form.phoneNumber'() {
      this.form.clearErrors('phoneNumber');
    },
  },
  mounted() {
    if (this.$page.props._user.name) {
      this.form.name = this.$page.props._user.name;
    }

    if (this.$page.props._user.phoneNumber) {
      this.form.phoneNumber = this.$page.props._user.phoneNumber;
    } else if (this.form.timezone === 'Asia/Dubai') {
      this.form.phoneNumber = '+971';
    } else if (this.phonePrefix) {
      this.form.phoneNumber = this.phonePrefix;
    }
  },
  methods: {
    async submit() {
      await this.form.post('/onboarding/user', {
        onSuccess: () => {
          //
        },
      });
    },
  },
};
</script>
