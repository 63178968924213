<script>
export default {
  watch: {
    '$page.props.flashMessages': {
      immediate: true,
      deep: true,
      handler(flashMessages) {
        this.$nextTick(() => {
          flashMessages.forEach(({ message, type }) => {
            if (type === 'info') {
              this.$toast.info(message);
            }
            if (type === 'success') {
              this.$toast.success(message);
            }
            if (type === 'error') {
              this.$toast.error(message);
            }
            if (type === 'warning') {
              this.$toast.warning(message);
            }
          });
        });
      },
    },
  },
  render: () => null,
};
</script>
