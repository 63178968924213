<template>
  <div class="flex flex-col">
    <template v-if="feature.isAvailable(benefits)">
      <CheckCircleBold class="h-5 w-5 text-lime-500" />
    </template>
    <template v-else>
      <XCircleBold class="h-5 w-5 text-gray-300" />
    </template>

    <div class="mt-1 text-xs empty:hidden">
      {{ feature.getClarification ? feature.getClarification(benefits) : null }}
    </div>
  </div>
</template>

<script>
import CheckCircleBold from '@/phosphoricons/CheckCircleBold.vue';
import XCircleBold from '@/phosphoricons/XCircleBold.vue';

export default {
  components: { XCircleBold, CheckCircleBold },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    benefits: {
      type: Object,
      required: true,
    },
  },
};
</script>
