<template>
  <DashboardWrapper title="Tablet menu">
    <ACard>
      <div class="mb-4">
        Tablet menu allows you to display your menu on iPad or Android tablet.
        <br />
        You can use it to show the menu at the front desk, at the table or for
        drive-through customers.
      </div>
      <div class="mb-4">Here is how you can add the menu to your tablet:</div>
      <div class="mb-4 flex flex-col">
        <div>
          <div>
            1. Open this link (<a
              :href="url"
              target="_blank"
              class="underline"
              >{{ url }}</a
            >) or scan this QR code using your tablet:
          </div>
          <AQRCode :value="url" class="mt-2" />
          <AAlert type="warning" class="mb-2 mt-3">
            <template #title> Important! </template>
            <template #description>
              <div>If you use iPad, you should open the link in Safari.</div>
              <div>
                If you use Android tablet, you should open the link in Google
                Chrome.
              </div>
            </template>
          </AAlert>
          <div>2. Click "Add to Home Screen".</div>
          <div>
            3. Open your menu from the tablet's home screen and wait till
            content is downloaded for offline use.
          </div>
          <div>
            4. Congratulations! You can now view menu from the tablet even
            without Wi-Fi connection.
          </div>
        </div>
      </div>
      <div>
        Need help?
        <Link href="/support" class="underline">Contact us.</Link>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AQRCode from '@app/Shared/AQRCode.vue';
import { Link } from '@inertiajs/vue3';
import AAlert from '@/app/Shared/AAlert.vue';

export default {
  components: {
    AAlert,
    AQRCode,
    ACard,
    DashboardWrapper,
    Link,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
