<template>
  <div>
    <div class="p-4 print:hidden">
      <div class="flex flex-col">
        <label for="scale" class="mb-1 inline-block">Scale</label>
        <input id="scale" v-model="scale" type="number" :step="0.1" />
      </div>
    </div>
    <div
      v-for="(table, tableIndex) in tables"
      :key="tableIndex"
      class="flex h-screen w-screen items-center justify-center"
    >
      <div class="flex items-center" :style="{ scale }">
        <QRCode
          ref="qr"
          :value="table.qrCodeUrl"
          :options="{
            width: 1024,
            margin: 3,
            color: {
              light: '#00000000',
              dark: '#000000',
            },
            errorCorrectionLevel: 'Q',
          }"
          tag="img"
          class="w-32"
        />

        <div
          class="ml-6 flex h-20 w-20 items-center justify-center rounded-full bg-black text-5xl font-semibold leading-none text-white"
        >
          {{ table.name }}
        </div>
      </div>

      <div style="page-break-before: always"></div>
    </div>
  </div>
</template>

<script>
import BlankLayout from '@/app/Shared/BlankLayout.vue';
import QRCode from '@chenfengyuan/vue-qrcode';

export default {
  components: { QRCode },
  layout: BlankLayout,
  props: {
    tables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scale: 1,
    };
  },
};
</script>
