<template>
  <div class="overflow-hidden rounded border">
    <div
      v-if="editor"
      class="flex flex-wrap space-x-2 border-b border-gray-300 p-1"
    >
      <HtmlEditorAction
        :active="editor.isActive('bold')"
        @run="editor.chain().focus().toggleBold().run()"
      >
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <path
            d="M72,120h80a40,40,0,0,1,0,80H72V48h68a36,36,0,0,1,0,72"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
        </svg>
      </HtmlEditorAction>
      <HtmlEditorAction
        :active="editor.isActive('italic')"
        @run="editor.chain().focus().toggleItalic().run()"
      >
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <line
            x1="152"
            y1="56"
            x2="104"
            y2="200"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
          <line
            x1="64"
            y1="200"
            x2="144"
            y2="200"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
          <line
            x1="112"
            y1="56"
            x2="192"
            y2="56"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
        </svg>
      </HtmlEditorAction>
      <HtmlEditorAction
        :active="editor.isActive('underline')"
        @run="editor.chain().focus().toggleUnderline().run()"
      >
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <line
            x1="64"
            y1="224"
            x2="192"
            y2="224"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
          <path
            d="M184,56v72a56,56,0,0,1-112,0V56"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
        </svg>
      </HtmlEditorAction>

      <HtmlEditorAction @run="editor.chain().focus().undo().run()">
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <polyline
            points="24 56 24 104 72 104"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
          <path
            d="M67.59,192A88,88,0,1,0,65.77,65.77L24,104"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
        </svg>
      </HtmlEditorAction>
      <HtmlEditorAction @run="editor.chain().focus().redo().run()">
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <polyline
            points="184 104 232 104 232 56"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
          <path
            d="M188.4,192a88,88,0,1,1,1.83-126.23L232,104"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          />
        </svg>
      </HtmlEditorAction>
    </div>
    <editor-content
      :editor="editor"
      :class="{ 'cursor-not-allowed bg-gray-100': disabled }"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import HardBreak from '@tiptap/extension-hard-break';
import Link from '@tiptap/extension-link';
import HtmlEditorAction from '@app/Shared/HtmlEditorAction.vue';

export default {
  components: {
    HtmlEditorAction,
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },

    disabled: {
      immediate: true,
      handler(disabled) {
        this.editor?.setEditable(!disabled);
      },
    },
  },

  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class:
            '[&>p]:mb-2 [&_a]:underline px-5 py-3 focus:outline-none overflow-auto',
          style: 'height: 150px;',
        },
      },
      extensions: [
        Document,
        Paragraph,
        Text,
        History,
        Bold,
        Underline,
        Italic,
        HardBreak,
        Link,
      ],
      content: this.modelValue,
      editable: !this.disabled,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML());

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
