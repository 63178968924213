<template>
  <DashboardWrapper title="Restaurant settings">
    <RestaurantSettingsTabs />

    <ACard class="mb-4">
      <form class="space-y-4" @submit.prevent="submit">
        <AInput
          v-model="form.name"
          label="Restaurant name"
          placeholder="Enter the name of your restaurant..."
          :error="form.errors.name"
        />

        <div>
          <AInput
            v-model="form.slug"
            label="Menu link"
            input-classes="pl-[188px]"
            :error="form.errors.slug"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
          >
            <template #relative-to-input>
              <div
                class="pointer-events-none absolute top-0 left-0 bottom-0 flex items-center px-3 text-gray-500"
              >
                menu.apetitomenu.com/
              </div>
            </template>
          </AInput>

          <p class="mt-1 text-xs text-gray-500">
            If you change the link, your existing QR codes and previous link
            will continue to work.
          </p>
        </div>

        <div
          :class="{
            '[&_.multiselect-tag:nth-child(n+2)]:bg-lime-500/50':
              restaurant.maxLanguages === 1,
            '[&_.multiselect-tag:nth-child(n+3)]:bg-lime-500/50':
              restaurant.maxLanguages === 2,
            '[&_.multiselect-tag:nth-child(n+4)]:bg-lime-500/50':
              restaurant.maxLanguages === 3,
            '[&_.multiselect-tag:nth-child(n+5)]:bg-lime-500/50':
              restaurant.maxLanguages === 4,
            '[&_.multiselect-tag:nth-child(n+6)]:bg-lime-500/50':
              restaurant.maxLanguages === 5,
            '[&_.multiselect-tag:nth-child(n+7)]:bg-lime-500/50':
              restaurant.maxLanguages === 6,
            '[&_.multiselect-tag:nth-child(n+8)]:bg-lime-500/50':
              restaurant.maxLanguages === 7,
            '[&_.multiselect-tag:nth-child(n+9)]:bg-lime-500/50':
              restaurant.maxLanguages === 8,
            '[&_.multiselect-tag:nth-child(n+10)]:bg-lime-500/50':
              restaurant.maxLanguages === 9,
            '[&_.multiselect-tag:nth-child(n+11)]:bg-lime-500/50':
              restaurant.maxLanguages === 10,
          }"
        >
          <AMultiselect
            v-model="form.languages"
            label="Menu languages"
            :options="languages"
            :value-resolver="(language) => language.code"
            :text-resolver="
              (language) => `${language.name} (${language.nativeName})`
            "
            :error="form.errors.languages"
          />

          <AAlert
            v-if="form.languages.length > restaurant.maxLanguages"
            type="warning"
            class="mt-4"
            :actions="[
              {
                label: 'Upgrade plan →',
                callback: () => {
                  $inertia.get('/billing/change-plan');
                },
              },
            ]"
          >
            <template #title>
              You can only use {{ restaurant.maxLanguages }} languages with your
              current plan.
            </template>
            <template #description>
              The following languages will be hidden from your menu:
              {{
                form.languages
                  .slice(restaurant.maxLanguages)
                  .map((language) =>
                    getLanguageByCode(language)
                      ? getLanguageByCode(language).name
                      : language,
                  )
                  .join(', ')
              }}.
            </template>
          </AAlert>
        </div>

        <ASelect
          v-model="form.currencyCode"
          label="Menu currency"
          :options="currencies"
          :value-resolver="(currency) => currency.code"
          :text-resolver="(currency) => currency.code"
          :error="form.errors.currencyCode"
        />

        <div
          class="sticky bottom-0 z-10 flex w-full items-center bg-white py-3"
        >
          <AButton type="submit" class="mt-3" :loading="form.processing">
            Save
          </AButton>
          <div v-if="form.isDirty" class="ml-3 text-gray-400">
            (has unsaved changes)
          </div>
        </div>
      </form>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import AInput from '@app/Shared/AInput.vue';
import { useForm } from '@inertiajs/vue3';
import ASelect from '@app/Shared/ASelect.vue';
import AButton from '@app/Shared/AButton.vue';
import ACard from '@app/Shared/ACard.vue';
import { getLanguageByCode, getLanguages } from '@/utils/language.js';
import sortBy from 'lodash/sortBy';
import AMultiselect from '@app/Shared/AMultiselect.vue';
import RestaurantSettingsTabs from '@app/Shared/Tabs/RestaurantSettingsTabs.vue';
import AAlert from '@/app/Shared/AAlert.vue';

export default {
  components: {
    AAlert,
    AMultiselect,
    ACard,
    AButton,
    ASelect,
    AInput,
    DashboardWrapper,
    RestaurantSettingsTabs,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
    restaurant: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        name: this.restaurant.name ?? '',
        slug: this.restaurant.slug ?? '',
        languages: this.restaurant.languages,
        currencyCode: this.restaurant.currencyCode,
      }),
    };
  },
  computed: {
    languages() {
      return sortBy(getLanguages(), 'name');
    },
  },
  methods: {
    submit() {
      this.form.put('/restaurant', {
        preserveScroll: true,
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        onSuccess: () => {
          this.$toast.success('Saved');
        },
      });
    },
    getLanguageByCode,
  },
};
</script>
