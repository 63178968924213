<template>
  <button
    type="button"
    class="flex h-6 w-6 items-center justify-center rounded uppercase text-gray-800 hover:bg-gray-200"
    :class="{ 'bg-gray-100 hover:bg-gray-200': active }"
    @click="$emit('run')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['run'],
};
</script>
