<template>
  <ATabs>
    <ATab
      v-for="branch in $page.props._restaurant.branches"
      :key="branch.id"
      :href="`/analytics?filters[branch_id]=${branch.id}`"
      :current="
        getURL().pathname === '/analytics' &&
        getURL().searchParams.get('filters[branch_id]') === String(branch.id)
      "
    >
      {{ $getPrimaryTranslation(branch.name) }}
    </ATab>
  </ATabs>
</template>

<script>
import ATabs from '@app/Shared/ATabs.vue';
import ATab from '@app/Shared/ATab.vue';
export default {
  components: { ATab, ATabs },
  methods: {
    getURL() {
      return new URL(location.href);
    },
  },
};
</script>
