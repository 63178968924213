<template>
  <InertiaLink
    :href="href"
    class="relative min-w-[8rem] shrink-0 whitespace-nowrap bg-white px-6 py-3 text-center font-medium transition first:rounded-l-lg last:rounded-r-lg hover:bg-gray-50"
    :class="current ? 'text-lime-600' : 'text-gray-700'"
  >
    <slot />
    <span
      v-show="current"
      aria-hidden="true"
      class="absolute inset-x-0 bottom-0 h-0.5 bg-lime-500"
    ></span>
  </InertiaLink>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    current: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
