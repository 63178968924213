<template>
  <DashboardWrapper title="Feedback forms">
    <ACard>
      <ACardHeader>
        <template #description>
          Forms can be used to collect feedback from your guests.
        </template>

        <template #actions>
          <AButton @click="modals.createForm = true">
            <template #icon><PlusBold /></template>
            Add form
          </AButton>
        </template>
      </ACardHeader>

      <div v-if="forms.length > 0">
        <div
          v-for="form in forms"
          :key="form.id"
          class="mb-2 flex justify-between rounded border border-dashed border-gray-200 bg-white px-1 py-1"
        >
          <div class="flex flex-1 items-center">
            <div class="ml-2">
              <div class="text-sm font-medium text-gray-900">
                {{ $getPrimaryTranslation(form.title) }}
              </div>
              <div class="text-xs text-gray-500">
                <div
                  v-for="(question, questionIndex) in form.questions"
                  :key="question.id"
                >
                  Question #{{ questionIndex + 1 }}:
                  {{ $getPrimaryTranslation(question.question) }} ({{
                    question.type
                  }}, {{ question.isMandatory ? 'required' : 'optional' }})
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-end space-x-2">
            <div class="p-1">
              <AToggle
                v-tippy="{
                  content: form.isEnabled ? 'Hide from menu' : 'Show in menu',
                }"
                :model-value="form.isEnabled"
                @update:model-value="
                  onIsEnabledUpdate({ form, isEnabled: $event })
                "
              />
            </div>

            <button
              v-tippy="{ content: 'Edit' }"
              type="button"
              class="p-1"
              @click="modals.editForm = form"
            >
              <PencilSimpleBold class="h-4 w-4 text-lime-500" />
            </button>
          </div>
        </div>
      </div>
      <div v-else>There are no feedback forms yet.</div>

      <CreateEditFormModal
        v-if="modals.createForm"
        @close="modals.createForm = false"
      />

      <CreateEditFormModal
        v-if="modals.editForm"
        :existing-form="modals.editForm"
        @close="modals.editForm = false"
      />
    </ACard>

    <ACard v-if="formQuestionsWithAverageAnswer.length > 0" class="mt-4">
      <div class="mb-4 flex justify-between">
        <div class="text-xl font-semibold">Average answers</div>
      </div>

      <div>
        <div
          v-for="(
            formQuestionWithAverageAnswer, formQuestionWithAverageAnswerIndex
          ) in formQuestionsWithAverageAnswer"
          :key="formQuestionWithAverageAnswerIndex"
        >
          <strong
            >{{ formQuestionWithAverageAnswer.translatedQuestion }}:</strong
          >
          {{
            Math.round(formQuestionWithAverageAnswer.averageAnswer * 100) / 100
          }}
        </div>
      </div>
    </ACard>

    <ACard class="mt-4">
      <div class="mb-4 flex justify-between">
        <div class="text-xl font-semibold">Responses</div>
      </div>

      <div v-if="formSubmissions.length > 0">
        <div
          v-for="(formSubmission, formSubmissionIndex) in formSubmissions"
          :key="formSubmissionIndex"
          class="mb-2 grid grid-cols-2 rounded border border-gray-300 p-2"
        >
          <div>
            <div>
              <strong>Form:</strong>
              {{ $getPrimaryTranslation(formSubmission.form.title) }}
            </div>
            <div>
              <strong>Menu:</strong>
              {{ $getPrimaryTranslation(formSubmission.branch.name) }}
            </div>
            <div>
              <strong>Date:</strong>
              {{ formatCreatedAt(formSubmission.createdAt) }}
            </div>
          </div>
          <div>
            <div
              v-for="(answer, answerIndex) in formSubmission.answers"
              :key="`${formSubmissionIndex}-${answerIndex}`"
            >
              <div>
                <strong>{{ $getPrimaryTranslation(answer.question) }}</strong
                >: {{ answer.answer ?? '--' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>There are no responses yet.</div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import ACard from '@app/Shared/ACard.vue';
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import CreateEditFormModal from '@app/Modals/CreateEditFormModal.vue';
import AButton from '@app/Shared/AButton.vue';
import AToggle from '@app/Shared/AToggle.vue';
import { DateTime } from 'luxon';
import ACardHeader from '@app/Shared/ACardHeader.vue';
import PencilSimpleBold from '@/phosphoricons/PencilSimpleBold.vue';
import PlusBold from '@/phosphoricons/PlusBold.vue';

export default {
  components: {
    PlusBold,
    PencilSimpleBold,
    ACardHeader,
    AToggle,
    AButton,
    CreateEditFormModal,
    DashboardWrapper,
    ACard,
  },
  props: {
    forms: {
      type: Array,
      required: true,
    },
    formQuestionsWithAverageAnswer: {
      type: Array,
      required: true,
    },
    formSubmissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modals: {
        createForm: false,
        editForm: false,
      },
    };
  },
  methods: {
    formatCreatedAt(createdAt) {
      return DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_MED);
    },
    async onIsEnabledUpdate({ form, isEnabled }) {
      form.isEnabled = isEnabled;

      await this.$inertia.put(
        `/forms/${form.id}/availability`,
        {
          isEnabled,
        },
        { preserveScroll: true },
      );
    },
  },
};
</script>
